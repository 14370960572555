




















































































































































































import { PackageInfo } from '@/api';
import Vue from '@/vue';
import { BModal } from 'bootstrap-vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';
import { helpers, required } from 'vuelidate/lib/validators';

const phone = (value: string) => {
  if (value !== undefined) {
    value = value.trim();
    // Match numeric, ignoring whitespace
    return (value.match(/^[\d +]*$/g) || [''])[0].length >= 5;
  }
  return true;
};

const email = helpers.regex(
  'email',
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
);

@Component
export default class BiContactUs extends Vue.extend({
  validations: {
    contactInfo: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      telephone: { required, phone },
    },
  },
  props: {
    value: {
      type: Object as PropType<PackageInfo>,
      required: false,
      default: () => {
        return {} as PackageInfo;
      },
    },
  },
}) {
  contactInfo: PackageInfo = {};

  savingEmail = false;

  get formInvalid(): boolean {
    return (
      this.$v.contactInfo.firstName?.$invalid ||
      this.$v.contactInfo.lastName?.$invalid ||
      this.$v.contactInfo.email?.$invalid ||
      this.$v.contactInfo.telephone?.$invalid ||
      false
    );
  }

  saveData(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.savingEmail = true;
      this.$store
        .dispatch(this.$actions.package.contact, {
          hash: this.$packageHash,
          info: this.contactInfo,
        })
        .then(() => {
          this.emit();
          (this.$refs.modalContactUs as BModal).hide();
        })
        .catch((err) => {
          // TODO show errror
        })
        .finally(() => {
          this.savingEmail = false;
        });
    }
  }

  emit(): void {
    this.$emit('input', this.contactInfo);
  }

  getData(): void {
    this.contactInfo = this.$clone(this.value);
  }

  beforeMount(): void {
    this.getData();
  }
}
