




















































































import { PackageForDashboardPoliciesLiability } from '@/api';
import BiFormView from '@/components/bi-form-view/bi-form-view.vue';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class DashboardPolicyBliView extends Vue.extend({
  props: {
    data: {
      type: Object as PropType<PackageForDashboardPoliciesLiability>,
    },
    complete: {
      type: Boolean,
      required: true,
      default: false,
    },
    showPremium: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
}) {
  policyWording = {
    publicGeneral: {
      text: "Covers your liability for accidental damage or loss of use of another person's property.\nIncludes cover for damage caused by faulty workmanship. Includes associated legal defense costs.",
      link: 'https://builtininsurance.co.nz/policies/public-liability/',
    },
    statutory: {
      text: 'Covers fines and penalties and pays legal defence costs due to unintentional breaches of legislation. These include the Building Act, Resource Management Act and Fair Trading Act. Additionally, the policy pays defence costs and reparations following a Health and Safety prosecution (but not the fine).',
      link: 'https://builtininsurance.co.nz/policies/statutory-liability/',
    },
    employers: {
      text: "Defends you against civil action by employees alleging workplace illness or injury that isn't covered by ACC.",
      link: 'https://builtininsurance.co.nz/policies/employers-liability/',
    },
  };
  get publicGeneralCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'gl');
  }
  get statutoryCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'sl');
  }
  get employersCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'el');
  }

  get liabilityRenewing(): boolean {
    return (
      this.data.liability?.id !== undefined &&
      this.data.liability.id !== null &&
      this.data.isRenewal === true
    );
  }

  get isPaid(): boolean {
    return this.data?.isPayable === false || false;
  }

  get underwriting(): boolean {
    return this.data.liability?.status === 'Underwriting-Insurer'
      || this.data.liability?.status === 'Underwriting-Client'
      || this.data.liability?.status === 'Underwriting-outside scheme'
  }

  get completeFormLabel(): string {
    return this.liabilityRenewing
      ? 'Confirm details to renew policy'
      : 'Complete form to add policy';
  }

  publicGeneralSaving = false;
  async savePublicGeneralLiability(): Promise<void> {
    this.publicGeneralSaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.publicGeneral,
        }),
        'gl'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.publicGeneralSaving = false;
    }
  }
  onLiabilityPublicGeneralChanged(): void {
    if (!this.$loading) {
      this.savePublicGeneralLiability();
      if (
        this.data.publicGeneral?.isInsured === undefined ||
        this.data.publicGeneral.isInsured === false ||
        this.data.publicGeneral.coverOf === undefined ||
        this.data.publicGeneral.coverOf === 'Not Included'
      ) {
        if (this.data.statutory !== undefined) {
          this.data.statutory.isInsured = false;
        }
        if (this.data.employers !== undefined) {
          this.data.employers.isInsured = false;
        }
      }
    }
  }

  statutorySaving = false;
  async saveStatutoryLiability(): Promise<void> {
    this.statutorySaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.statutory,
        }),
        'sl'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.statutorySaving = false;
    }
  }
  onLiabilityStatutoryChanged(): void {
    if (!this.$loading) {
      this.saveStatutoryLiability();
    }
  }

  employersSaving = false;
  async saveEmployersLiability(): Promise<void> {
    this.employersSaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.employers,
        }),
        'el'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.employersSaving = false;
    }
  }
  onLiabilityEmployersChanged(): void {
    if (!this.$loading) {
      this.saveEmployersLiability();
    }
  }

  open(): void {
    (this.$refs.formView as BiFormView).open();
  }

  async save(): Promise<void[]> {
    return Promise.all([
      this.savePublicGeneralLiability(),
      this.saveStatutoryLiability(),
      this.saveEmployersLiability(),
    ]);
  }
}
