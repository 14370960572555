var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bi-form-view',{ref:"formView",staticClass:"my-4",attrs:{"title":"Liability","description":"Mistakes happen and when they do you could be held responsible for the consequences, whether they're caused by you, an employee or a subcontractor you're responsible for. Examples include accidentally damaging someone's property, health & safety prosecutions and employment disputes. Choose from a range of covers to suit your needs.","more-info-link":"https://builtininsurance.co.nz/policies/liability/","icon":_vm.$icons.liability,"collapsible":_vm.data.isRenewal,"complete":_vm.complete,"form-link":_vm.$getFormPath('liability', '1'),"complete-form-label":_vm.completeFormLabel,"show-premium":_vm.showPremium,"premium":_vm.data.subTotalNett,"is-paid":_vm.isPaid,"is-insured":_vm.data.liability.isInsured,"referred":_vm.data.liability.refer || _vm.underwriting,"no-edit-form":_vm.readonly || _vm.underwriting}},[[_c('bi-form-view-item',{staticClass:"mb-3",attrs:{"title":"Public/General Liability","inputLabel":"Cover of","options":_vm.publicGeneralCoverOfLookup,"tooltip":{
        title: 'Public/General Liability',
        content: _vm.policyWording.publicGeneral.text,
      },"info-link":_vm.policyWording.publicGeneral.link,"input-type":"select","saving":_vm.publicGeneralSaving,"show-premium":_vm.showPremium,"is-paid":_vm.isPaid,"referred":_vm.data.liability.refer || _vm.underwriting,"disabled":_vm.readonly || !_vm.complete || _vm.underwriting},on:{"input":_vm.onLiabilityPublicGeneralChanged,"insured":_vm.onLiabilityPublicGeneralChanged},model:{value:(_vm.data.publicGeneral),callback:function ($$v) {_vm.$set(_vm.data, "publicGeneral", $$v)},expression:"data.publicGeneral"}}),_vm._v(" "),_c('bi-form-view-item',{staticClass:"my-3",attrs:{"title":"Statutory Liability","inputLabel":"Cover of","options":_vm.statutoryCoverOfLookup,"tooltip":{
        title: 'Statutory Liability',
        content: _vm.policyWording.statutory.text,
      },"info-link":_vm.policyWording.statutory.link,"input-type":"select","saving":_vm.statutorySaving,"show-premium":_vm.showPremium,"is-paid":_vm.isPaid,"referred":_vm.data.liability.refer || _vm.underwriting,"disabled":!_vm.data.publicGeneral.isInsured || _vm.readonly || !_vm.complete || _vm.underwriting},on:{"input":_vm.onLiabilityStatutoryChanged,"insured":_vm.onLiabilityStatutoryChanged},model:{value:(_vm.data.statutory),callback:function ($$v) {_vm.$set(_vm.data, "statutory", $$v)},expression:"data.statutory"}}),_vm._v(" "),_c('bi-form-view-item',{staticClass:"my-3",attrs:{"title":"Employers Liability","inputLabel":"Cover of","options":_vm.employersCoverOfLookup,"tooltip":{
        title: 'Employers Liability',
        content: _vm.policyWording.employers.text,
      },"info-link":_vm.policyWording.employers.link,"input-type":"select","saving":_vm.employersSaving,"show-premium":_vm.showPremium,"is-paid":_vm.isPaid,"referred":_vm.data.liability.refer || _vm.underwriting,"disabled":!_vm.data.publicGeneral.isInsured || _vm.readonly || !_vm.complete || _vm.underwriting},on:{"input":_vm.onLiabilityEmployersChanged,"insured":_vm.onLiabilityEmployersChanged},model:{value:(_vm.data.employers),callback:function ($$v) {_vm.$set(_vm.data, "employers", $$v)},expression:"data.employers"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }