import { RouteConfig } from 'vue-router';
import * as handlers from './handlers';
import Vue from "@/vue";
import {PackageApi} from "@/api";

const routes: Array<RouteConfig> = [
  { path: '', component: () => import('@/pages/WelcomePage.vue') },
  ...(process.env.NODE_ENV !== 'production'
    ? [
        {
          path: '/dev/testing',
          component: () => import('@/layouts/MainLayout.vue'),
          children: [
            {
              path: 'routes',
              component: () => import('@/pages/testing/TestRoutes.vue')
            },
            {
              path: 'components',
              component: () => import('@/pages/testing/Components.vue')
            },
            { path: 'api', component: () => import('@/pages/testing/Api.vue') }
          ]
        }
      ]
    : []),
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        name: 'quote',
        path: 'quote/:packageHash?',
        component: () => import('@/layouts/QuoteLayout.vue'),
        children: [
          {
            path: 'your-details',
            component: () => import('@/pages/quote/YourDetailsPage.vue'),
            meta: {
              back: handlers.home,
              noHashAllowed: true
            }
          },
          {
            path: 'other-details',
            component: () =>
              import('@/pages/quote/renewal/RenewalOtherDetailsPage.vue'),
            meta: {
              back(this: Vue): string {
                return `/quote/${this.$packageHash}/your-details`;
              }
            }
          },
          { path: 'view', redirect: 'dashboard' },
          {
            path: 'dashboard',
            component: () => import('@/pages/quote/DashboardPage.vue'),
            meta: {
              async back(this: Vue): Promise<string> {
                const response = await new PackageApi().getPackageStatus(this.$packageHash);
                return (response.status === 200 && response.data.data?.status === 'ReferForQuote') ?
                    handlers.requote.call(this) :
                    `/quote/${this.$packageHash}/your-details`;
              },
              transitions: {
                from: {
                  '/quote/form/liability': 'slide-fade-right',
                  '/quote/form/tools': 'slide-fade-right',
                  '/quote/form/commercial-vehicles': 'slide-fade-right',
                }
              }
            }
          },
          {
            path: 'form/',
            component: () => import('@/pages/quote/forms/FormPage.vue'),
            children: [
              {
                path: 'liability/',
                component: () =>
                  import('@/pages/quote/forms/liability/LiabilityForm.vue'),
                children: [
                  {
                    path: '1',
                    component: () =>
                      import(
                        '@/pages/quote/forms/liability/pages/LiabilityPageGlSlEl.vue'
                      ),
                    meta: {
                      back: handlers.dashboard
                    }
                  },
                  {
                    path: '2',
                    component: () =>
                      import(
                        '@/pages/quote/forms/liability/pages/LiabilityPageDeclaredInsolvent.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('liability', '1');
                      }
                    }
                  },
                  {
                    path: '3',
                    component: () =>
                      import(
                        '@/pages/quote/forms/liability/pages/LiabilityPageSpecifiedActivities.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('liability', '2');
                      }
                    }
                  },
                  {
                    path: '4',
                    component: () =>
                      import(
                        '@/pages/quote/forms/liability/pages/LiabilityPageClaims.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('liability', '3');
                      }
                    }
                  },
                ]
              },
              {
                path: 'tools/',
                component: () =>
                  import(
                    '@/pages/quote/forms/toolsAndEquipment/ToolsAndEquipmentForm.vue'
                  ),
                children: [
                  {
                    path: '1',
                    component: () =>
                      import(
                        '@/pages/quote/forms/toolsAndEquipment/pages/ToolsAndEquipmentPage1.vue'
                      ),
                    meta: {
                      back: handlers.dashboard
                    }
                  },
                  {
                    path: '2',
                    component: () =>
                      import(
                        '@/pages/quote/forms/toolsAndEquipment/pages/ToolsAndEquipmentPage2.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('tools', '1');
                      }
                    }
                  },
                  {
                    path: '3',
                    component: () =>
                      import(
                        '@/pages/quote/forms/toolsAndEquipment/pages/ToolsAndEquipmentPage3.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('tools', '2');
                      }
                    }
                  },
                  {
                    path: '4',
                    component: () =>
                      import(
                        '@/pages/quote/forms/toolsAndEquipment/pages/ToolsAndEquipmentPage4.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('tools', '3');
                      }
                    }
                  }
                ]
              },
              {
                path: 'commercial-vehicles',
                component: () =>
                  import(
                    '@/pages/quote/forms/commercialVehicles/CommercialVehiclesForm.vue'
                  ),
                children: [
                  {
                    path: '1',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage1.vue'
                      ),
                    meta: {
                      back: handlers.dashboard
                    }
                  },
                  {
                    path: '2',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage2.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('commercial-vehicles', '1');
                      }
                    }
                  },
                  {
                    path: '3',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage3.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('commercial-vehicles', '2');
                      }
                    }
                  },
                  {
                    path: '4',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage4.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('commercial-vehicles', '3');
                      }
                    }
                  },
                  {
                    path: '5',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage5.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('commercial-vehicles', '4');
                      }
                    }
                  },
                  {
                    path: '5/:vehicleId',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage5.vue'
                      ),
                    meta: {
                      back: handlers.dashboard
                    }
                  },
                  {
                    path: '6',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage6.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath('commercial-vehicles', '5');
                      }
                    }
                  },
                  {
                    path: '6/:vehicleId',
                    component: () =>
                      import(
                        '@/pages/quote/forms/commercialVehicles/pages/CommercialVehiclesPage6.vue'
                      ),
                    meta: {
                      back(this: Vue): string {
                        return this.$getFormPath(
                          'commercial-vehicles',
                          `5/${this.$route.params.vehicleId}`
                        );
                      }
                    }
                  }
                ]
              },
            ]
          },
          {
            path: 'apply',
            component: () => import('@/pages/application/ApplyPage.vue'),
            meta: {
              back: handlers.requote
            }
          },
          {
            path: 'payment/thanks',
            component: () => import('@/pages/payment/SuccessPage.vue'),
            meta: {
              back: handlers.home
            }
          },
          {
            path: 'payment/finance',
            component: () => import('@/pages/payment/FinancialSynergyPage.vue'),
            meta: {
              back(this: Vue): string {
                return `/quote/${this.$packageHash}/payment`
              }
            }
          },
          {
            path: 'payment',
            component: () => import('@/pages/payment/PaymentPage.vue'),
            children: [{ path: ':id' }],
            meta: {
              back(this: Vue): string {
                return `/quote/${this.$packageHash}/apply`;
              }
            }
          },
          {
            path: 'superseded',
            component: () => import('@/pages/error/ErrorPackageSupersededPage.vue'),
            meta: {
              back: handlers.home
            }
          },
          {
            path: 'current',
            component: () => import('@/pages/error/ErrorPackageCurrentPage.vue'),
            meta: {
              back: handlers.home
            }
          },
          {
            path: 'declined',
            component: () => import('@/pages/error/ErrorPackageDeclinedPage.vue'),
            meta: {
              back: handlers.home
            }
          },
          {
            path: 'expired',
            component: () => import('@/pages/error/ErrorPackageExpiredPage.vue'),
            meta: {
              back: handlers.home
            }
          },
        ]
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/pages/error/Error404Page.vue')
  }
];

export default routes;
