/* tslint:disable */
/* eslint-disable */
/**
 * BuiltIn Portal API
 * Quoting and applying for Packages of BuiltIn Insurance Policies.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: liam@triotech.co.nz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    ownerId?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    ownerType?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    fullAdr?: string;
}
/**
 * Answers are for one policy group.
 * @export
 * @interface Answers
 */
export interface Answers {
    /**
     * The policy group type.
     * @type {string}
     * @memberof Answers
     */
    type?: string;
    /**
     * Answers for a specific Policy Group.
     * @type {AnswersBli | AnswersMdt | AnswersCmv | AnswersPi | AnswersDo}
     * @memberof Answers
     */
    answers?: AnswersBli | AnswersMdt | AnswersCmv | AnswersPi | AnswersDo;
    /**
     * 
     * @type {boolean}
     * @memberof Answers
     */
    isFormCompleted?: boolean;
}
/**
 * 
 * @export
 * @interface AnswersBli
 */
export interface AnswersBli {
    /**
     * policy_bli id.
     * @type {number}
     * @memberof AnswersBli
     */
    id?: number;
    /**
     * Public/General Liability - Describe the sites you work at.
     * @type {string}
     * @memberof AnswersBli
     */
    industrialSites?: string;
    /**
     * Public/General Liability - Describe the work you do there.
     * @type {string}
     * @memberof AnswersBli
     */
    industrialWorkTypes?: string;
    /**
     * Public/General Liability - Does this include using hotwork?
     * @type {boolean}
     * @memberof AnswersBli
     */
    isHotwork?: boolean;
    /**
     * 
     * @type {PolicyLiability}
     * @memberof AnswersBli
     */
    publicGeneral?: PolicyLiability;
    /**
     * 
     * @type {PolicyLiability}
     * @memberof AnswersBli
     */
    statutory?: PolicyLiability;
    /**
     * 
     * @type {PolicyLiability}
     * @memberof AnswersBli
     */
    employers?: PolicyLiability;
    /**
     * 
     * @type {PolicyLiability}
     * @memberof AnswersBli
     */
    directorsAndOfficers?: PolicyLiability;
    /**
     * 
     * @type {PolicyLiability}
     * @memberof AnswersBli
     */
    employeeDisputes?: PolicyLiability;
    /**
     * 
     * @type {PolicyLiability}
     * @memberof AnswersBli
     */
    crimeFidelity?: PolicyLiability;
    /**
     * 
     * @type {PolicyLiability}
     * @memberof AnswersBli
     */
    legalProsecutionDefenceCosts?: PolicyLiability;
    /**
     * Liability Claims - Have you (either personally or under a trading/company name) made any Liability claims totalling more than $,3000 in the last 3 years? Stored on policy_version.
     * @type {boolean}
     * @memberof AnswersBli
     */
    isMadeClaim?: boolean;
    /**
     * An array of policy claims. Note: Actions on these is intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyClaim>}
     * @memberof AnswersBli
     */
    bliLiabilityClaims?: Array<PolicyClaim>;

    declaredInsolvent?: boolean;
    involvedInSpecifiedActivity?: boolean;
    involvedInSpecifiedActivityOther?: string
}
/**
 * 
 * @export
 * @interface AnswersCmv
 */
export interface AnswersCmv {
    /**
     * policy_cmv id.
     * @type {number}
     * @memberof AnswersCmv
     */
    id?: number | null;
    /**
     * Had any claims over $10,000.
     * @type {boolean}
     * @memberof AnswersCmv
     */
    largeClaim?: boolean | null;
    /**
     * An array of policy claims. Note: Actions on these are intended to be done with the relevant policy claim endpoints.  Note: Claims also have a vehicleID indicating that they are for a specific Policy Vehicle.
     * @type {Array<PolicyClaim>}
     * @memberof AnswersCmv
     */
    cmvLargeClaims?: Array<PolicyClaim>;
    /**
     * Had more than 2 speeding fines in the last 2 years? Stored on policy_version.
     * @type {boolean}
     * @memberof AnswersCmv
     */
    isCriminalOffense?: boolean | null;
    /**
     * Been convicted, fined or have a prosecution pending for any criminal or driving offence in the last 7 years?
     * @type {boolean}
     * @memberof AnswersCmv
     */
    driverLicenceIssues?: boolean | null;
    /**
     * Finally, had previous insurance cancelled or any special terms imposed by an insurer?
     * @type {boolean}
     * @memberof AnswersCmv
     */
    driverDeclinedClaim?: boolean | null;
    /**
     * An array of policy events. Note: Actions on these are intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyEvent>}
     * @memberof AnswersCmv
     */
    cmvCriminalOffenses?: Array<PolicyEvent>;
    /**
     * An array of policy events. Note: Actions on these are intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyEvent>}
     * @memberof AnswersCmv
     */
    cmvDriverLicenceIssues?: Array<PolicyEvent>;
    /**
     * An array of policy events. Note: Actions on these are intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyEvent>}
     * @memberof AnswersCmv
     */
    cmvDriverDeclinedClaims?: Array<PolicyEvent>;
    /**
     * 
     * @type {Array<PolicyVehicle>}
     * @memberof AnswersCmv
     */
    vehicles?: Array<PolicyVehicle>;
    /**
     * 
     * @type {boolean}
     * @memberof AnswersCmv
     */
    isInsured?: boolean;
}
/**
 * 
 * @export
 * @interface AnswersDo
 */
export interface AnswersDo {
    /**
     * 
     * @type {string}
     * @memberof AnswersDo
     */
    id?: string | null;
    /**
     * Amout of cover you require.  Lookup from /lookups/general/cover/do
     * @type {number}
     * @memberof AnswersDo
     */
    limit?: number | null;
    /**
     * The directors on this Policy.
     * @type {Array<PolicyDirector>}
     * @memberof AnswersDo
     */
    directors?: Array<PolicyDirector>;
    /**
     * Have you made any Directors and Officers Liability claims in the last 3 years? Either personally or under a trading/company name.
     * @type {boolean}
     * @memberof AnswersDo
     */
    isMadeClaim?: boolean | null;
    /**
     * An array of policy claims. Note: Actions on these is intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyClaim>}
     * @memberof AnswersDo
     */
    doLiabilityClaims?: Array<PolicyClaim>;
    /**
     * Finally, has any legal action been taken against you, a partner or director? Or are there any other known circumstances whicm ay give rise to a claim.
     * @type {boolean}
     * @memberof AnswersDo
     */
    isLegalAction?: boolean | null;
    /**
     * An array of policy claims. Note: Actions on these is intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyClaim>}
     * @memberof AnswersDo
     */
    doLegalActions?: Array<PolicyClaim>;
    /**
     * 
     * @type {boolean}
     * @memberof AnswersDo
     */
    isInsured?: boolean;
}
/**
 * 
 * @export
 * @interface AnswersInData
 */
export interface AnswersInData {
    /**
     * 
     * @type {Answers}
     * @memberof AnswersInData
     */
    data?: Answers;
}
/**
 * 
 * @export
 * @interface AnswersMdt
 */
export interface AnswersMdt {
    /**
     * policy_mdt id.
     * @type {number}
     * @memberof AnswersMdt
     */
    id?: number | null;
    /**
     * Have you had any tools or equipement claims int he last three years?
     * @type {boolean}
     * @memberof AnswersMdt
     */
    isMadeClaim?: boolean | null;
    /**
     * What is the replacement value of the tools & equipment to be insured. This is also coverOf on the dashboard for MDT.
     * @type {number}
     * @memberof AnswersMdt
     */
    toolValue?: number | null;
    /**
     * Value of any scaffolding included in the previous question. If none, leave empty.
     * @type {number}
     * @memberof AnswersMdt
     */
    scaffoldingValue?: number | null;
    /**
     * An array of policy claims. Note: Actions on these are intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyClaim>}
     * @memberof AnswersMdt
     */
    mdtToolEquipmentClaims?: Array<PolicyClaim>;
    /**
     * 
     * @type {Array<PolicySpecifiedItem>}
     * @memberof AnswersMdt
     */
    specifiedItems?: Array<PolicySpecifiedItem>;
    /**
     * 
     * @type {boolean}
     * @memberof AnswersMdt
     */
    isInsured?: boolean;
}
/**
 * 
 * @export
 * @interface AnswersPi
 */
export interface AnswersPi {
    /**
     * 
     * @type {string}
     * @memberof AnswersPi
     */
    id?: string | null;
    /**
     * Amout of cover you require.  Lookup from /lookups/general/cover/pi
     * @type {number}
     * @memberof AnswersPi
     */
    cover?: number | null;
    /**
     * How many years experience do you have in the trade?  Stored in policy_version.
     * @type {number}
     * @memberof AnswersPi
     */
    experience?: number | null;
    /**
     * How many years have you been in business?
     * @type {number}
     * @memberof AnswersPi
     */
    howLong?: number | null;
    /**
     * The directors on this Policy.
     * @type {Array<PolicyDirector>}
     * @memberof AnswersPi
     */
    directors?: Array<PolicyDirector>;
    /**
     * Have you made any Professional Indemnity claims in the last 3 years? Either personally or under a trading/company name.
     * @type {boolean}
     * @memberof AnswersPi
     */
    isMadeClaim?: boolean | null;
    /**
     * An array of policy claims. Note: Actions on these is intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyClaim>}
     * @memberof AnswersPi
     */
    piIndemnityClaims?: Array<PolicyClaim>;
    /**
     * An array of policy claims. Note: Actions on these is intended to be done with the relevant policy claim endpoints.
     * @type {Array<PolicyClaim>}
     * @memberof AnswersPi
     */
    piLegalActions?: Array<PolicyClaim>;
    /**
     * Finally, has any legal action been taken against you, a partner or director? Or are there any other known circumstances whicm ay give rise to a claim.
     * @type {boolean}
     * @memberof AnswersPi
     */
    isLegalAction?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnswersPi
     */
    isInsured?: boolean;
}
/**
 * 
 * @export
 * @interface FinancialSynergyFirstPayment
 */
export interface FinancialSynergyFirstPayment {
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyFirstPayment
     */
    deposit?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyFirstPayment
     */
    firstInstalment?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyFirstPayment
     */
    overdueInstalment?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyFirstPayment
     */
    fslAdminFee?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyFirstPayment
     */
    firstPaymentRequired?: string;
}
/**
 * 
 * @export
 * @interface FinancialSynergyInfo
 */
export interface FinancialSynergyInfo {
    /**
     * 
     * @type {FinancialSynergyInstallments}
     * @memberof FinancialSynergyInfo
     */
    instalments?: FinancialSynergyInstallments;
    /**
     * 
     * @type {FinancialSynergyFirstPayment}
     * @memberof FinancialSynergyInfo
     */
    firstPayment?: FinancialSynergyFirstPayment;
    /**
     * 
     * @type {FinancialSynergySummary}
     * @memberof FinancialSynergyInfo
     */
    summary?: FinancialSynergySummary;
    /**
     * 
     * @type {FinancialSynergyInterest}
     * @memberof FinancialSynergyInfo
     */
    interest?: FinancialSynergyInterest;
}
/**
 * 
 * @export
 * @interface FinancialSynergyInstallments
 */
export interface FinancialSynergyInstallments {
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyInstallments
     */
    monthlyInstalment?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyInstallments
     */
    firstInstalmentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyInstallments
     */
    lastInstalmentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyInstallments
     */
    numberOfInstalments?: string;
}
/**
 * 
 * @export
 * @interface FinancialSynergyInterest
 */
export interface FinancialSynergyInterest {
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyInterest
     */
    flatRate?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergyInterest
     */
    interestAmount?: string;
}
/**
 * 
 * @export
 * @interface FinancialSynergySummary
 */
export interface FinancialSynergySummary {
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergySummary
     */
    total?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergySummary
     */
    lessDeposit?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergySummary
     */
    credit?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergySummary
     */
    costOfCredit?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergySummary
     */
    totalLoan?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergySummary
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialSynergySummary
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface ForgotPassword
 */
export interface ForgotPassword {
    /**
     * 
     * @type {string}
     * @memberof ForgotPassword
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPassword
     */
    app?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {PolicyForDashboard | PolicyLiabilityForDashboard | PolicyVehicleForDashboard}
     * @memberof InlineObject
     */
    data?: PolicyForDashboard | PolicyLiabilityForDashboard | PolicyVehicleForDashboard;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {InlineResponse200Data}
     * @memberof InlineResponse200
     */
    data?: InlineResponse200Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {InlineResponse2001Data}
     * @memberof InlineResponse2001
     */
    data?: InlineResponse2001Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2001Data
 */
export interface InlineResponse2001Data {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2001Data
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse200Data
 */
export interface InlineResponse200Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Data
     */
    hash?: string;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    password?: string;
}
/**
 * Lookups for general things such as dropdowns/selects.
 * @export
 * @interface LookupGeneral
 */
export interface LookupGeneral {
    /**
     * The Lookup ID.
     * @type {number}
     * @memberof LookupGeneral
     */
    ID?: number;
    /**
     * The type of lookup.
     * @type {string}
     * @memberof LookupGeneral
     */
    type?: string;
    /**
     * A subtype given to a type of lookup, often this is a policy sub type.
     * @type {string}
     * @memberof LookupGeneral
     */
    subtype?: string;
    /**
     * The text label given to this lookup in selects.
     * @type {string}
     * @memberof LookupGeneral
     */
    label?: string;
    /**
     * Value is a string, but can in some cases need to be converted to another type e.g. Decimal, Integer.
     * @type {string}
     * @memberof LookupGeneral
     */
    value?: string;
    /**
     * Sort order of lookup items.
     * @type {number}
     * @memberof LookupGeneral
     */
    sort?: number;
}
/**
 * 
 * @export
 * @interface LookupGeneralInData
 */
export interface LookupGeneralInData {
    /**
     * 
     * @type {LookupGeneral}
     * @memberof LookupGeneralInData
     */
    data?: LookupGeneral;
}
/**
 * 
 * @export
 * @interface LookupGeneralsInData
 */
export interface LookupGeneralsInData {
    /**
     * 
     * @type {Array<LookupGeneral>}
     * @memberof LookupGeneralsInData
     */
    data?: Array<LookupGeneral>;
}
/**
 * 
 * @export
 * @interface LookupInterestedParty
 */
export interface LookupInterestedParty {
    /**
     * The Lookup Region id.
     * @type {number}
     * @memberof LookupCities
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupCities
     */
    name?: string;
}
/**
 * 
 * @export
 *  * @interface LookupInterestedPartyInData
 */
export interface LookupInterestedPartyInData {
    /**
     * 
     * @type {Array<LookupInterestedParty>}
     * @memberof LookupInterestedPartyInData
     */
    data?: Array<LookupInterestedParty>;
}
/**
 * 
 * @export
 * @interface LookupLossOfUsePremiums
 */
export interface LookupLossOfUsePremiums {
    /**
     * The Lookup loss of use cover.
     * @type {number}
     * @memberof LookupLossOfUsePremiums
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupLossOfUsePremiums
     */
    maximumAmountPerDay?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupLossOfUsePremiums
     */
    lossOfUseLength?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupLossOfUsePremiums
     */
    premium?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LookupLossOfUsePremiums
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LookupLossOfUsePremiums
     */
    effectiveFrom?: string;
    /**
     * The text label given to this lookup in selects.
     * @type {string}
     * @memberof LookupLossOfUsePremiums
     */
    label?: string;
    /**
     * Value is a string, but can in some cases need to be converted to another type e.g. Decimal, Integer.
     * @type {string}
     * @memberof LookupLossOfUsePremiums
     */
    value?: string;
    /**
     * A column name given to a type of lookup, to request particular data.
     * @type {string}
     * @memberof LookupLossOfUsePremiums
     */
    column?: string;
}
/**
 * 
 * @export
 * @interface LookupLossOfUsePremiumsInData
 */
export interface LookupLossOfUsePremiumsInData {
    /**
     * 
     * @type {Array<LookupLossOfUsePremiums>}
     * @memberof LookupLossOfUsePremiumsInData
     */
    data?: Array<LookupLossOfUsePremiums>;
}
/**
 * 
 * @export
 * @interface LookupRegion
 */
export interface LookupRegion {
    /**
     * The Lookup Region id.
     * @type {number}
     * @memberof LookupRegion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupRegion
     */
    region?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LookupRegion
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LookupRegion
     */
    sort?: number;
}
/**
 * 
 * @export
 * @interface LookupCities
 */
export interface LookupCities {
    /**
     * The Lookup Region id.
     * @type {number}
     * @memberof LookupCities
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupCities
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface LookupRegionInData
 */
export interface LookupRegionInData {
    /**
     * 
     * @type {LookupRegion}
     * @memberof LookupRegionInData
     */
    data?: LookupRegion;
}
/**
 * 
 * @export
 * @interface LookupRegionsInData
 */
export interface LookupRegionsInData {
    /**
     * 
     * @type {Array<LookupRegion>}
     * @memberof LookupRegionsInData
     */
    data?: Array<LookupRegion>;
}
/**
 * 
 * @export
 * @interface LookupCitiesInData
 */
export interface LookupCitiesInData {
    /**
     * 
     * @type {Array<LookupCities>}
     * @memberof LookupCitiesInData
     */
    data?: Array<LookupCities>;
}
/**
 * 
 * @export
 * @interface LookupSector
 */
export interface LookupSector {
    /**
     * The Lookup Sector id.
     * @type {number}
     * @memberof LookupSector
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupSector
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LookupSector
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LookupSector
     */
    sort?: number;
}
/**
 * 
 * @export
 * @interface LookupSectorInData
 */
export interface LookupSectorInData {
    /**
     * 
     * @type {LookupSector}
     * @memberof LookupSectorInData
     */
    data?: LookupSector;
}
/**
 * 
 * @export
 * @interface LookupSectorsInData
 */
export interface LookupSectorsInData {
    /**
     * 
     * @type {Array<LookupSector>}
     * @memberof LookupSectorsInData
     */
    data?: Array<LookupSector>;
}
/**
 * 
 * @export
 * @interface LookupTrade
 */
export interface LookupTrade {
    /**
     * The Lookup Trade id.
     * @type {number}
     * @memberof LookupTrade
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LookupTrade
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LookupTrade
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LookupTrade
     */
    sort?: number;
}
/**
 * 
 * @export
 * @interface LookupTradeInData
 */
export interface LookupTradeInData {
    /**
     * 
     * @type {LookupTrade}
     * @memberof LookupTradeInData
     */
    data?: LookupTrade;
}
/**
 * 
 * @export
 * @interface LookupTradesInData
 */
export interface LookupTradesInData {
    /**
     * 
     * @type {Array<LookupTrade>}
     * @memberof LookupTradesInData
     */
    data?: Array<LookupTrade>;
}
/**
 * 
 * @export
 * @interface LookupWording
 */
export interface LookupWording {
    /**
     * The Lookup Wording id.
     * @type {number}
     * @memberof LookupWording
     */
    ID?: number;
    /**
     * The Product Type (policyType)
     * @type {string}
     * @memberof LookupWording
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupWording
     */
    startdate?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupWording
     */
    enddate?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupWording
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupWording
     */
    wording_url?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupWording
     */
    wording_name?: string;
}
/**
 * 
 * @export
 * @interface LookupWordingInData
 */
export interface LookupWordingInData {
    /**
     * 
     * @type {LookupWording}
     * @memberof LookupWordingInData
     */
    data?: LookupWording;
}
/**
 * 
 * @export
 * @interface LookupWordingsInData
 */
export interface LookupWordingsInData {
    /**
     * 
     * @type {Array<LookupWording>}
     * @memberof LookupWordingsInData
     */
    data?: Array<LookupWording>;
}
/**
 * 
 * @export
 * @interface Org
 */
export interface Org {
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    tradingName?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    mobile?: string;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    adminId?: number;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Org
     */
    address?: Array<Address>;
}
/**
 * 
 * @export
 * @interface OrgInData
 */
export interface OrgInData {
    /**
     * 
     * @type {Org}
     * @memberof OrgInData
     */
    data?: Org;
}
/**
 * 
 * @export
 * @interface OrgRole
 */
export interface OrgRole {
    /**
     * 
     * @type {number}
     * @memberof OrgRole
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrgRole
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgRole
     */
    supplierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgRole
     */
    role?: string;
}
/**
 * 
 * @export
 * @interface OrgRoleInData
 */
export interface OrgRoleInData {
    /**
     * 
     * @type {OrgRole}
     * @memberof OrgRoleInData
     */
    data?: OrgRole;
}
/**
 * 
 * @export
 * @interface OrgRolesInData
 */
export interface OrgRolesInData {
    /**
     * 
     * @type {Array<OrgRole>}
     * @memberof OrgRolesInData
     */
    data?: Array<OrgRole>;
}
/**
 * 
 * @export
 * @interface OrgsInData
 */
export interface OrgsInData {
    /**
     * 
     * @type {Array<Org>}
     * @memberof OrgsInData
     */
    data?: Array<Org>;
}
/**
 * 
 * @export
 * @interface Package
 */
export interface Package {
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    id?: number | null;
    /**
     * A package hash is a hashid of the Package Id.
     * @type {string}
     * @memberof Package
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    submitDate?: string | null;
    /**
     * 
     * @type {PackageInfo}
     * @memberof Package
     */
    info?: PackageInfo;
}
/**
 * 
 * @export
 * @interface PackageForDashboard
 */
export interface PackageForDashboard {
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboard
     */
    id?: number | null;
    /**
     * A package hash is a hashid of the Package Id
     * @type {string}
     * @memberof PackageForDashboard
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboard
     */
    status?: string;
    /**
     * Package hash of package which supersedes this one.
     * @type {string}
     * @memberof PackageForDashboard
     */
    supersededBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboard
     */
    submitDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboard
     */
    isCloned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboard
     */
    isRenewal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboard
     */
    hasPayment?: boolean;
    /**
     * 
     * @type {PackageInfo}
     * @memberof PackageForDashboard
     */
    info?: PackageInfo;
    /**
     * 
     * @type {PackageForDashboardPromoCode}
     * @memberof PackageForDashboard
     */
    promoCode?: PackageForDashboardPromoCode;
    /**
     * 
     * @type {PackageForDashboardSummary}
     * @memberof PackageForDashboard
     */
    summary?: PackageForDashboardSummary;
    /**
     * 
     * @type {PackageForDashboardPolicies}
     * @memberof PackageForDashboard
     */
    policies?: PackageForDashboardPolicies;
}
/**
 * 
 * @export
 * @interface PackageForDashboardInData
 */
export interface PackageForDashboardInData {
    /**
     * 
     * @type {PackageForDashboard}
     * @memberof PackageForDashboardInData
     */
    data?: PackageForDashboard;
}
/**
 * 
 * @export
 * @interface PackageForDashboardPolicies
 */
export interface PackageForDashboardPolicies {
    /**
     * 
     * @type {PackageForDashboardPoliciesLiability}
     * @memberof PackageForDashboardPolicies
     */
    liability?: PackageForDashboardPoliciesLiability;
    /**
     * 
     * @type {PackageForDashboardPoliciesToolsAndEquipment}
     * @memberof PackageForDashboardPolicies
     */
    toolsAndEquipment?: PackageForDashboardPoliciesToolsAndEquipment;
    /**
     * 
     * @type {PackageForDashboardPoliciesCommercialVehicles}
     * @memberof PackageForDashboardPolicies
     */
    commercialVehicles?: PackageForDashboardPoliciesCommercialVehicles;
    /**
     * 
     * @type {PackageForDashboardPoliciesProfessionalIndemnity}
     * @memberof PackageForDashboardPolicies
     */
    professionalIndemnity?: PackageForDashboardPoliciesProfessionalIndemnity;
    /**
     * 
     * @type {PackageForDashboardPoliciesDirectorsAndOfficers}
     * @memberof PackageForDashboardPolicies
     */
    directorsAndOfficers?: PackageForDashboardPoliciesDirectorsAndOfficers;
}
/**
 * 
 * @export
 * @interface PackageForDashboardPoliciesCommercialVehicles
 */
export interface PackageForDashboardPoliciesCommercialVehicles {
    /**
     * 
     * @type {PolicyForDashboard}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    commercialVehicles?: PolicyForDashboard;
    /**
     * 
     * @type {Array<PolicyVehicleForDashboard>}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    vehicles?: Array<PolicyVehicleForDashboard>;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    subTotalNett?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    gst?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    adminFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    commission?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    isAvailable?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    isVisible?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    isRenewal?: boolean | null;
    /**
     * 
     * @type {PolicyInsurerForDashboard}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    insurer?: PolicyInsurerForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    financeCostPerMonth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    isPayable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    isFormCompleted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboardPoliciesCommercialVehicles
     */
    refNumber?: string;
}
/**
 * 
 * @export
 * @interface PackageForDashboardPoliciesDirectorsAndOfficers
 */
export interface PackageForDashboardPoliciesDirectorsAndOfficers {
    /**
     * 
     * @type {PolicyForDashboard}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    directorsAndOfficers?: PolicyForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    gst?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    adminFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    commission?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    isAvailable?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    isVisible?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    isRenewal?: boolean | null;
    /**
     * 
     * @type {PolicyInsurerForDashboard}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    insurer?: PolicyInsurerForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    financeCostPerMonth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    isPayable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    isFormCompleted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboardPoliciesDirectorsAndOfficers
     */
    refNumber?: string;
}
/**
 * 
 * @export
 * @interface PackageForDashboardPoliciesLiability
 */
export interface PackageForDashboardPoliciesLiability {
    /**
     * 
     * @type {PolicyForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    liability?: PolicyForDashboard;
    /**
     * 
     * @type {PolicyLiabilityForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    publicGeneral?: PolicyLiabilityForDashboard;
    /**
     * 
     * @type {PolicyLiabilityForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    statutory?: PolicyLiabilityForDashboard;
    /**
     * 
     * @type {PolicyLiabilityForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    employers?: PolicyLiabilityForDashboard;
    /**
     * 
     * @type {PolicyLiabilityForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    directorsAndOfficers?: PolicyLiabilityForDashboard;
    /**
     * 
     * @type {PolicyLiabilityForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    employeeDisputes?: PolicyLiabilityForDashboard;
    /**
     * 
     * @type {PolicyLiabilityForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    crimeFidelity?: PolicyLiabilityForDashboard;
    /**
     * 
     * @type {PolicyLiabilityForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    legalProsecutionDefenceCosts?: PolicyLiabilityForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesLiability
     */
    subTotalNett?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesLiability
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesLiability
     */
    gst?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesLiability
     */
    adminFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesLiability
     */
    commission?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesLiability
     */
    isAvailable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesLiability
     */
    isVisible?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesLiability
     */
    isRenewal?: boolean | null;
    /**
     * 
     * @type {PolicyInsurerForDashboard}
     * @memberof PackageForDashboardPoliciesLiability
     */
    insurer?: PolicyInsurerForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesLiability
     */
    financeCostPerMonth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesLiability
     */
    isPayable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesLiability
     */
    isFormCompleted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboardPoliciesLiability
     */
    refNumber?: string;
}
/**
 * 
 * @export
 * @interface PackageForDashboardPoliciesProfessionalIndemnity
 */
export interface PackageForDashboardPoliciesProfessionalIndemnity {
    /**
     * 
     * @type {PolicyForDashboard}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    professionalIndemnity?: PolicyForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    gst?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    adminFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    commission?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    isAvailable?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    isVisible?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    isRenewal?: boolean | null;
    /**
     * 
     * @type {PolicyInsurerForDashboard}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    insurer?: PolicyInsurerForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    financeCostPerMonth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    isPayable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    isFormCompleted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboardPoliciesProfessionalIndemnity
     */
    refNumber?: string;
}
/**
 * 
 * @export
 * @interface PackageForDashboardPoliciesToolsAndEquipment
 */
export interface PackageForDashboardPoliciesToolsAndEquipment {
    /**
     * 
     * @type {PolicyForDashboard}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    toolsAndEquipment?: PolicyForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    subTotalNett?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    gst?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    adminFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    commission?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    isAvailable?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    isVisible?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    isRenewal?: boolean | null;
    /**
     * 
     * @type {PolicyInsurerForDashboard}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    insurer?: PolicyInsurerForDashboard;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    financeCostPerMonth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    isPayable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    isFormCompleted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboardPoliciesToolsAndEquipment
     */
    refNumber?: string;
}
/**
 * 
 * @export
 * @interface PackageForDashboardPromoCode
 */
export interface PackageForDashboardPromoCode {
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboardPromoCode
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageForDashboardPromoCode
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardPromoCode
     */
    discountValue?: number | null;
}
/**
 * 
 * @export
 * @interface PackageForDashboardSummary
 */
export interface PackageForDashboardSummary {
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardSummary
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardSummary
     */
    gst?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardSummary
     */
    total?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardSummary
     */
    financeCostPerMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardSummary
     */
    additionalPremium?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardSummary
     */
    adminFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageForDashboardSummary
     */
    commission?: number | null;
}
/**
 * 
 * @export
 * @interface PackageInData
 */
export interface PackageInData {
    /**
     * 
     * @type {Package}
     * @memberof PackageInData
     */
    data?: Package;
}
/**
 * Stores information throughout the lifetime of the Package.  This information is not nessecarily all asked in the same place. Some comes from the initial Welcome and Your Details sections. Others would come from the View My Quote Pop Up or the Apply Now process. 
 * @export
 * @interface PackageInfo
 */
export interface PackageInfo {
    /**
     * First Name
     * @type {string}
     * @memberof PackageInfo
     */
    firstName?: string | null;
    /**
     * Last Name
     * @type {string}
     * @memberof PackageInfo
     */
    lastName?: string | null;
    /**
     * Business Name/Legal Name
     * @type {string}
     * @memberof PackageInfo
     */
    legalName?: string | null;
    /**
     * Trading Name
     * @type {string}
     * @memberof PackageInfo
     */
    tradingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    telephone?: string | null;
    /**
     * Asked on View my quote Pop Up  Is there any other information that could affect acceptance of this insurance?  Asked on Apply Now page  Other information you wish to be made known to the insurer.
     * @type {string}
     * @memberof PackageInfo
     */
    otherInfoInsurer?: string | null;
    /**
     * Lookup from /lookups/general/referral
     * @type {string}
     * @memberof PackageInfo
     */
    referral?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    referralOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    postalAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    postalSuburb?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    postalCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    postalPostcode?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageInfo
     */
    primaryTrades?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageInfo
     */
    secondaryTrades?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    otherTrade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    areaOfOp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    mainSector?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PackageInfo
     */
    estTurnover?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageInfo
     */
    principalManual?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    coverStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    tradingStyle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    signFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    signLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    newCoverStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    hasDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    changeDetails?: string;
}
/**
 * 
 * @export
 * @interface PackageInfoInData
 */
export interface PackageInfoInData {
    /**
     * 
     * @type {PackageInfo}
     * @memberof PackageInfoInData
     */
    data?: PackageInfo;
}
/**
 * Returns the status of the Package
 * @export
 * @interface PackageStatus
 */
export interface PackageStatus {
    /**
     * Package Status
     * @type {string}
     * @memberof PackageStatus
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface PackageStatusInData
 */
export interface PackageStatusInData {
    /**
     * 
     * @type {PackageStatus}
     * @memberof PackageStatusInData
     */
    data?: PackageStatus;
}
/**
 * 
 * @export
 * @interface PackagesInData
 */
export interface PackagesInData {
    /**
     * 
     * @type {Array<Package>}
     * @memberof PackagesInData
     */
    data?: Array<Package>;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    id?: number | null;
    /**
     * Indicates success or fail. Possible Values  Success Pending Fail  And possibly later RefundPending Refunded
     * @type {string}
     * @memberof Payment
     */
    status?: string | null;
    /**
     * The payment_types.code related by paymentTypeId.
     * @type {string}
     * @memberof Payment
     */
    paymentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    responseStatus?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    amount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    amountPaid?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    responseAt?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentFinanceRequest
 */
export interface PaymentFinanceRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentFinanceRequest
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFinanceRequest
     */
    bank?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFinanceRequest
     */
    accountNumber?: string;
}
/**
 * 
 * @export
 * @interface PaymentInData
 */
export interface PaymentInData {
    /**
     * 
     * @type {Payment}
     * @memberof PaymentInData
     */
    data?: Payment;
}
/**
 * Used to request a payment method redirect URL.
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
    /**
     * The payment type code. See payment_types table.
     * @type {string}
     * @memberof PaymentRequest
     */
    paymentType: PaymentRequestPaymentTypeEnum;
    /**
     * An optional amount for a sanity check between frontend and backend. (Although it should always be the same in practice). This might not be required.
     * @type {number}
     * @memberof PaymentRequest
     */
    amount?: number;
    /**
     * The status e.g. if the user can simply goto the Thankyou/Success page such as with a Manual/Invoice type of payment or when redirecting to a finance partner perhaps.
     * @type {string}
     * @memberof PaymentRequest
     */
    status?: string;
    /**
     * The Payment Gateway URL to redirect to if required. If no redirect nessecarry null or empty is fine.
     * @type {string}
     * @memberof PaymentRequest
     */
    redirectUrl?: string | null;
    /**
     * The payment record id managed by the API.
     * @type {number}
     * @memberof PaymentRequest
     */
    paymentId?: number | null;
    /**
     * 
     * @type {PaymentRequestOmnipayCreditCard}
     * @memberof PaymentRequest
     */
    omnipayCreditCard?: PaymentRequestOmnipayCreditCard;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentRequestPaymentTypeEnum {
    Windcave = 'Windcave',
    Invoice = 'Invoice',
    FinancialSynergy = 'FinancialSynergy'
}

/**
 * 
 * @export
 * @interface PaymentRequestInData
 */
export interface PaymentRequestInData {
    /**
     * 
     * @type {PaymentRequest}
     * @memberof PaymentRequestInData
     */
    data?: PaymentRequest;
}
/**
 * An optional Credit Card Object for use with Omnipay Payment Gateways where applicable.  https://omnipay.thephpleague.com/api/cards/  Note: Not presently used.
 * @export
 * @interface PaymentRequestOmnipayCreditCard
 */
export interface PaymentRequestOmnipayCreditCard {
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    expiryMonth?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    expiryYear?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    startMonth?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    startYear?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    cvv?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    issueNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    billingAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    billingAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    billingCity?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    billingPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    billingState?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    billingCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    billingPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    shippingAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    shippingAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    shippingCity?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    shippingPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    shippingState?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    shippingCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    shippingPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    email?: string;
    /**
     * Optional cardReference for stored cards e.g. for omnipay-paymentexpress if we use that functionality.
     * @type {string}
     * @memberof PaymentRequestOmnipayCreditCard
     */
    cardReference?: string;
}
/**
 * 
 * @export
 * @interface PolicyClaim
 */
export interface PolicyClaim {
    /**
     * policy_claims id.
     * @type {number}
     * @memberof PolicyClaim
     */
    claimID?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyClaim
     */
    policyID?: number;
    /**
     * Stores the question that this Claim was recorded for.
     * @type {string}
     * @memberof PolicyClaim
     */
    question?: string;
    /**
     * Lookup from /lookups/general/claimfor/cmv
     * @type {string}
     * @memberof PolicyClaim
     */
    whatHappened?: string;
    /**
     * If \"Other\" selected for whatHappened then this is filled in by the user.
     * @type {string}
     * @memberof PolicyClaim
     */
    whatHappenedOther?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicyClaim
     */
    claimMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyClaim
     */
    claimYear?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyClaim
     */
    claimValue?: number;
    /**
     * The ID of a Vehicle this claim is associated with.
     * @type {number}
     * @memberof PolicyClaim
     */
    vehicleID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyClaim
     */
    atFault?: boolean;
}
/**
 * 
 * @export
 * @interface PolicyClaimInData
 */
export interface PolicyClaimInData {
    /**
     * 
     * @type {PolicyClaim}
     * @memberof PolicyClaimInData
     */
    data?: PolicyClaim;
}
/**
 * 
 * @export
 * @interface PolicyClaimsInData
 */
export interface PolicyClaimsInData {
    /**
     * 
     * @type {Array<PolicyClaim>}
     * @memberof PolicyClaimsInData
     */
    data?: Array<PolicyClaim>;
}
/**
 * 
 * @export
 * @interface PolicyDirector
 */
export interface PolicyDirector {
    /**
     * The policy_directors id.
     * @type {number}
     * @memberof PolicyDirector
     */
    id?: number;
    /**
     * The policy id that the directors belong to.
     * @type {number}
     * @memberof PolicyDirector
     */
    policyID?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyDirector
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyDirector
     */
    experience?: string;
}
/**
 * 
 * @export
 * @interface PolicyDirectorInData
 */
export interface PolicyDirectorInData {
    /**
     * 
     * @type {PolicyDirector}
     * @memberof PolicyDirectorInData
     */
    data?: PolicyDirector;
}
/**
 * 
 * @export
 * @interface PolicyDirectorsInData
 */
export interface PolicyDirectorsInData {
    /**
     * 
     * @type {Array<PolicyDirector>}
     * @memberof PolicyDirectorsInData
     */
    data?: Array<PolicyDirector>;
}
/**
 * 
 * @export
 * @interface PolicyEvent
 */
export interface PolicyEvent {
    /**
     * policy_events id.
     * @type {number}
     * @memberof PolicyEvent
     */
    eventID?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyEvent
     */
    policyID?: number;
    /**
     * Stores the question that this Event was recorded for.
     * @type {string}
     * @memberof PolicyEvent
     */
    question?: string;
    /**
     * Lookup from /lookups/general/eventfor/policyType
     * @type {string}
     * @memberof PolicyEvent
     */
    whatHappened?: string;
    /**
     * If \"Other\" selected for whatHappened then this is filled in by the user.
     * @type {string}
     * @memberof PolicyEvent
     */
    whatHappenedOther?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicyEvent
     */
    eventMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyEvent
     */
    eventYear?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyEvent
     */
    penalty?: string;
}
/**
 * 
 * @export
 * @interface PolicyEventInData
 */
export interface PolicyEventInData {
    /**
     * 
     * @type {PolicyEvent}
     * @memberof PolicyEventInData
     */
    data?: PolicyEvent;
}
/**
 * 
 * @export
 * @interface PolicyEventsInData
 */
export interface PolicyEventsInData {
    /**
     * 
     * @type {Array<PolicyEvent>}
     * @memberof PolicyEventsInData
     */
    data?: Array<PolicyEvent>;
}
/**
 * 
 * @export
 * @interface PolicyForDashboard
 */
export interface PolicyForDashboard {
    /**
     * The policy id.
     * @type {number}
     * @memberof PolicyForDashboard
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyForDashboard
     */
    isInsured?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyForDashboard
     */
    isRenewal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PolicyForDashboard
     */
    coverOf?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyForDashboard
     */
    premium?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyForDashboard
     */
    refer?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyForDashboard
     */
    status?: PolicyForDashboardStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PolicyForDashboardStatusEnum {
    Draft = 'Draft',
    Completed = 'Completed',
    MustRefer = 'MustRefer',
    Quoted = 'Quoted',
    ReferForQuote = 'ReferForQuote',
    Declined = 'Declined',
    AuthorisedQuote = 'AuthorisedQuote',
    Superseded = 'Superseded',
    UnderwritingInsurer = 'Underwriting-Insurer',
    UnderwritingClient = 'Underwriting-Client',
    UnderwritingOutsideScheme = 'Underwriting-outside scheme',
}

/**
 * 
 * @export
 * @interface PolicyInsurerForDashboard
 */
export interface PolicyInsurerForDashboard {
    /**
     * The Insurer Rating
     * @type {string}
     * @memberof PolicyInsurerForDashboard
     */
    rating?: string;
    /**
     * The rating scale for the rating agency
     * @type {string}
     * @memberof PolicyInsurerForDashboard
     */
    ratingScale?: string;
    /**
     * The name of the insurer
     * @type {string}
     * @memberof PolicyInsurerForDashboard
     */
    insurerName?: string;
    /**
     * More info for the rating agency
     * @type {string}
     * @memberof PolicyInsurerForDashboard
     */
    moreInfo?: string;
    /**
     * Full text for the insurer rating
     * @type {string}
     * @memberof PolicyInsurerForDashboard
     */
    text?: string;
    /**
     * Extra info for the rating
     * @type {string}
     * @memberof PolicyInsurerForDashboard
     */
    footnotes?: string;
}
/**
 * 
 * @export
 * @interface PolicyLiability
 */
export interface PolicyLiability {
    /**
     * 
     * @type {number}
     * @memberof PolicyLiability
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyLiability
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyLiability
     */
    limit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyLiability
     */
    excess?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyLiability
     */
    premium?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyLiability
     */
    isInsured?: boolean;
}
/**
 * 
 * @export
 * @interface PolicyLiabilityForDashboard
 */
export interface PolicyLiabilityForDashboard {
    /**
     * The policy liability id.
     * @type {number}
     * @memberof PolicyLiabilityForDashboard
     */
    id?: number | null;
    /**
     * The policy sub type e.g. publicGeneral, crimeFidelity...
     * @type {string}
     * @memberof PolicyLiabilityForDashboard
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyLiabilityForDashboard
     */
    isInsured?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyLiabilityForDashboard
     */
    coverOf?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyLiabilityForDashboard
     */
    premium?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyLiabilityForDashboard
     */
    refer?: boolean | null;
}
/**
 * 
 * @export
 * @interface PolicySpecifiedItem
 */
export interface PolicySpecifiedItem {
    /**
     * policy_specifiedItems itemID.
     * @type {number}
     * @memberof PolicySpecifiedItem
     */
    itemID?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicySpecifiedItem
     */
    item?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicySpecifiedItem
     */
    itemValue?: number;
}
/**
 * 
 * @export
 * @interface PolicySpecifiedItemInData
 */
export interface PolicySpecifiedItemInData {
    /**
     * 
     * @type {PolicySpecifiedItem}
     * @memberof PolicySpecifiedItemInData
     */
    data?: PolicySpecifiedItem;
}
/**
 * 
 * @export
 * @interface PolicySpecifiedItemsInData
 */
export interface PolicySpecifiedItemsInData {
    /**
     * 
     * @type {Array<PolicySpecifiedItem>}
     * @memberof PolicySpecifiedItemsInData
     */
    data?: Array<PolicySpecifiedItem>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PolicyType = {
    Bli: 'BLI',
    Mdt: 'MDT',
    Cmv: 'CMV',
    Pi: 'PI',
    Do: 'DO'
} as const;

export type PolicyType = typeof PolicyType[keyof typeof PolicyType];


/**
 * A Vehicle listed on a Policy.  Note: Any claims on a vehicle will be stored on the Policy but the PolicyClaim.vehicleID will be set.
 * @export
 * @interface PolicyVehicle
 */
export interface PolicyVehicle {
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyVehicle
     */
    isTrailer?: boolean;
    /**
     * Lookup from /lookups/general/year
     * @type {string}
     * @memberof PolicyVehicle
     */
    year?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicle
     */
    make?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicle
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicle
     */
    registration?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    marketvalue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyVehicle
     */
    heavyvehicle?: boolean;
    /**
     * Type of cover.  Lookup from /lookups/general/coverType/CMV
     * @type {string}
     * @memberof PolicyVehicle
     */
    typeofcover?: string;
    /**
     * Add loss of use.  Lookup from /lookups/general/lossOfUse/cmv
     * @type {string}
     * @memberof PolicyVehicle
     */
    lossofuse?: string;
    /**
     * Add loss of use maximum amount per day.  Lookup from /lookups/lossofusepremiums
     * @type {string}
     * @memberof PolicyVehicle
     */
    lossOfUseCover?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    basePremium?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    lossOfUsePremium?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    fsl?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    premium?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    penaltyPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    penaltyAmount?: number;
    /**
     * Has the vehicle been involved in any at fault claims in the last 2 years or did it replace a vehicle that had?  OR  Has the trailer been involved in any at fault claims in the last 2 years or did it replace a trailer that had?
     * @type {boolean}
     * @memberof PolicyVehicle
     */
    involvedInClaim?: boolean;
    /**
     * For CMV an array of policy claims related to the vehicle.
     * @type {Array<PolicyClaim>}
     * @memberof PolicyVehicle
     */
    cmvPolicyVehicleClaims?: Array<PolicyClaim>;
    /**
     * Is there an interested party that must be named on the policy?
     * @type {boolean}
     * @memberof PolicyVehicle
     */
    hasInterestedParties?: boolean;
    /**
     * Lookup from /lookups/general/interestedParties/cmv
     * @type {string}
     * @memberof PolicyVehicle
     */
    interestedPartiesDetails?: string;
    /**
     * Enter name of the party.
     * @type {string}
     * @memberof PolicyVehicle
     */
    interestedPartiesDetailsOther?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    discountPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicle
     */
    discountAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyVehicle
     */
    oldVehicle?: boolean;
    /**
     * If the Policy Vehicle is marked as Insured.
     * @type {boolean}
     * @memberof PolicyVehicle
     */
    isInsured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicle
     */
    status?: string;
}
/**
 * A Policy Vehicle model for viewing the Vehicle on the Package Dashboard 
 * @export
 * @interface PolicyVehicleForDashboard
 */
export interface PolicyVehicleForDashboard {
    /**
     * The policy_vehicle id.
     * @type {number}
     * @memberof PolicyVehicleForDashboard
     */
    id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyVehicleForDashboard
     */
    isTrailer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicleForDashboard
     */
    year?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicleForDashboard
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicleForDashboard
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicleForDashboard
     */
    registration?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicleForDashboard
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicleForDashboard
     */
    typeofcover?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyVehicleForDashboard
     */
    coverOf?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolicyVehicleForDashboard
     */
    premium?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyVehicleForDashboard
     */
    isInsured?: boolean;
}
/**
 * 
 * @export
 * @interface PolicyVehicleInData
 */
export interface PolicyVehicleInData {
    /**
     * 
     * @type {PolicyVehicle}
     * @memberof PolicyVehicleInData
     */
    data?: PolicyVehicle;
}
/**
 * 
 * @export
 * @interface PolicyVehiclesInData
 */
export interface PolicyVehiclesInData {
    /**
     * 
     * @type {Array<PolicyVehicle>}
     * @memberof PolicyVehiclesInData
     */
    data?: Array<PolicyVehicle>;
}
/**
 * 
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    password_confirmation?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * role id
     * @type {number}
     * @memberof Role
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    orgId?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    action?: string;
}
/**
 * 
 * @export
 * @interface SaveAndEmailData
 */
export interface SaveAndEmailData {
    /**
     * 
     * @type {string}
     * @memberof SaveAndEmailData
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    authType?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    expiresIn?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    orgId?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    preferences?: object;
}
/**
 * 
 * @export
 * @interface UserInData
 */
export interface UserInData {
    /**
     * 
     * @type {User}
     * @memberof UserInData
     */
    data?: User;
}
/**
 * 
 * @export
 * @interface UsersInData
 */
export interface UsersInData {
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersInData
     */
    data?: Array<User>;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current user.
         * @summary Get Logged in User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets Logged in Organisation Roles.
         * @summary Get Logged in Org Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserOrgs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actions user forgot password functionality.
         * @summary Forgot Password
         * @param {ForgotPassword} forgotPassword Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPassword: ForgotPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPassword' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPassword', forgotPassword)
            const localVarPath = `/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login as another user.
         * @summary Login as another user. Uses url generated by /auth/loginAsUrl/{id}
         * @param {number} userId userId
         * @param {string} expires timestamp when this link expires
         * @param {string} signature signature that validates the url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAs: async (userId: number, expires: string, signature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loginAs', 'userId', userId)
            // verify required parameter 'expires' is not null or undefined
            assertParamExists('loginAs', 'expires', expires)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('loginAs', 'signature', signature)
            const localVarPath = `/auth/loginAs/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expires !== undefined) {
                localVarQueryParameter['expires'] = expires;
            }

            if (signature !== undefined) {
                localVarQueryParameter['signature'] = signature;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates a loginAsURL.
         * @summary Generates url for /auth/loginAsUrl/{id}. Must be logged in as admin to use this
         * @param {number} userId userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsUrl: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loginAsUrl', 'userId', userId)
            const localVarPath = `/auth/loginAsUrl/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User login.
         * @summary Logs user into the system
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (login?: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout the user.
         * @summary Logs out current logged in user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh the auth token.
         * @summary Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resends user activation email.
         * @summary Resend User Activation
         * @param {string} userId activation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserActivation: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resendUserActivation', 'userId', userId)
            const localVarPath = `/auth/resend/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset a users password.
         * @summary Reset User Password post
         * @param {ResetPassword} resetPassword Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPassword: ResetPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassword' is not null or undefined
            assertParamExists('resetPassword', 'resetPassword', resetPassword)
            const localVarPath = `/auth/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a user.
         * @summary Update Current User
         * @param {User} user User Object to Update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateCurrentUser', 'user', user)
            const localVarPath = `/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify a users email address.
         * @summary Signed Verification of User Email Address
         * @param {number} userId userId
         * @param {string} expires Activation code
         * @param {string} signature Signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (userId: number, expires: string, signature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('verifyEmail', 'userId', userId)
            // verify required parameter 'expires' is not null or undefined
            assertParamExists('verifyEmail', 'expires', expires)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('verifyEmail', 'signature', signature)
            const localVarPath = `/auth/verify/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expires !== undefined) {
                localVarQueryParameter['expires'] = expires;
            }

            if (signature !== undefined) {
                localVarQueryParameter['signature'] = signature;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current user.
         * @summary Get Logged in User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets Logged in Organisation Roles.
         * @summary Get Logged in Org Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserOrgs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgRolesInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserOrgs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actions user forgot password functionality.
         * @summary Forgot Password
         * @param {ForgotPassword} forgotPassword Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPassword: ForgotPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Login as another user.
         * @summary Login as another user. Uses url generated by /auth/loginAsUrl/{id}
         * @param {number} userId userId
         * @param {string} expires timestamp when this link expires
         * @param {string} signature signature that validates the url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAs(userId: number, expires: string, signature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAs(userId, expires, signature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates a loginAsURL.
         * @summary Generates url for /auth/loginAsUrl/{id}. Must be logged in as admin to use this
         * @param {number} userId userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAsUrl(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAsUrl(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User login.
         * @summary Logs user into the system
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(login?: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logout the user.
         * @summary Logs out current logged in user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refresh the auth token.
         * @summary Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resends user activation email.
         * @summary Resend User Activation
         * @param {string} userId activation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserActivation(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserActivation(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reset a users password.
         * @summary Reset User Password post
         * @param {ResetPassword} resetPassword Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPassword: ResetPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a user.
         * @summary Update Current User
         * @param {User} user User Object to Update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUser(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verify a users email address.
         * @summary Signed Verification of User Email Address
         * @param {number} userId userId
         * @param {string} expires Activation code
         * @param {string} signature Signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(userId: number, expires: string, signature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(userId, expires, signature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Get current user.
         * @summary Get Logged in User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUser(options?: any): AxiosPromise<UserInData> {
            return localVarFp.authUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets Logged in Organisation Roles.
         * @summary Get Logged in Org Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserOrgs(options?: any): AxiosPromise<OrgRolesInData> {
            return localVarFp.authUserOrgs(options).then((request) => request(axios, basePath));
        },
        /**
         * Actions user forgot password functionality.
         * @summary Forgot Password
         * @param {ForgotPassword} forgotPassword Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPassword: ForgotPassword, options?: any): AxiosPromise<string> {
            return localVarFp.forgotPassword(forgotPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Login as another user.
         * @summary Login as another user. Uses url generated by /auth/loginAsUrl/{id}
         * @param {number} userId userId
         * @param {string} expires timestamp when this link expires
         * @param {string} signature signature that validates the url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAs(userId: number, expires: string, signature: string, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.loginAs(userId, expires, signature, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates a loginAsURL.
         * @summary Generates url for /auth/loginAsUrl/{id}. Must be logged in as admin to use this
         * @param {number} userId userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsUrl(userId: number, options?: any): AxiosPromise<string> {
            return localVarFp.loginAsUrl(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * User login.
         * @summary Logs user into the system
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(login?: Login, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.loginUser(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout the user.
         * @summary Logs out current logged in user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser(options?: any): AxiosPromise<void> {
            return localVarFp.logoutUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh the auth token.
         * @summary Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.refreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * Resends user activation email.
         * @summary Resend User Activation
         * @param {string} userId activation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserActivation(userId: string, options?: any): AxiosPromise<string> {
            return localVarFp.resendUserActivation(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset a users password.
         * @summary Reset User Password post
         * @param {ResetPassword} resetPassword Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPassword: ResetPassword, options?: any): AxiosPromise<string> {
            return localVarFp.resetPassword(resetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a user.
         * @summary Update Current User
         * @param {User} user User Object to Update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser(user: User, options?: any): AxiosPromise<string> {
            return localVarFp.updateCurrentUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify a users email address.
         * @summary Signed Verification of User Email Address
         * @param {number} userId userId
         * @param {string} expires Activation code
         * @param {string} signature Signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(userId: number, expires: string, signature: string, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.verifyEmail(userId, expires, signature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Get current user.
     * @summary Get Logged in User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets Logged in Organisation Roles.
     * @summary Get Logged in Org Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserOrgs(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUserOrgs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actions user forgot password functionality.
     * @summary Forgot Password
     * @param {ForgotPassword} forgotPassword Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(forgotPassword: ForgotPassword, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).forgotPassword(forgotPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login as another user.
     * @summary Login as another user. Uses url generated by /auth/loginAsUrl/{id}
     * @param {number} userId userId
     * @param {string} expires timestamp when this link expires
     * @param {string} signature signature that validates the url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAs(userId: number, expires: string, signature: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAs(userId, expires, signature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates a loginAsURL.
     * @summary Generates url for /auth/loginAsUrl/{id}. Must be logged in as admin to use this
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAsUrl(userId: number, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAsUrl(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User login.
     * @summary Logs user into the system
     * @param {Login} [login] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginUser(login?: Login, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginUser(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout the user.
     * @summary Logs out current logged in user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logoutUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logoutUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh the auth token.
     * @summary Refresh Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resends user activation email.
     * @summary Resend User Activation
     * @param {string} userId activation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resendUserActivation(userId: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resendUserActivation(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset a users password.
     * @summary Reset User Password post
     * @param {ResetPassword} resetPassword Created user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(resetPassword: ResetPassword, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetPassword(resetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a user.
     * @summary Update Current User
     * @param {User} user User Object to Update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateCurrentUser(user: User, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).updateCurrentUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify a users email address.
     * @summary Signed Verification of User Email Address
     * @param {number} userId userId
     * @param {string} expires Activation code
     * @param {string} signature Signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyEmail(userId: number, expires: string, signature: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyEmail(userId, expires, signature, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LookupApi - axios parameter creator
 * @export
 */
export const LookupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get General lookups by a lookupType string.
         * @summary Get general Lookup
         * @param {string} type The type of general lookup to filter on.
         * @param {string} [subtype] The subtype to filter by. Generally its a Policy Type (Product Type) but could be something else.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralLookups: async (type: string, subtype?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getGeneralLookups', 'type', type)
            const localVarPath = `/lookups/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (subtype !== undefined) {
                localVarQueryParameter['subtype'] = subtype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get loss of use cover for selection.
         * @summary Get trade lookups
         * @param {string} [column] The type of general lookup to filter on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLossOfUsePremiumLookup: async (column?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lookups/lossofusepremiums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (column !== undefined) {
                localVarQueryParameter['column'] = column;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get regions for selection.
         * @summary Get regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionLookups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lookups/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get cities for selection.
         * @summary Get cities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesLookups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lookups/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sectors for selection.
         * @summary Get sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorLookups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lookups/sectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get trades for selection.
         * @summary Get trade lookups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeLookups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lookups/trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
        * Get interestedParty for selection.
        * @summary Get interestedParty
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
       getInterestedPartyLookups: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/lookups/interested-party`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
            baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (type !== undefined) {
            localVarQueryParameter['type'] = type;
        }



        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

        return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
    }
};

/**
 * LookupApi - functional programming interface
 * @export
 */
export const LookupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LookupApiAxiosParamCreator(configuration)
    return {
        /**
         * Get General lookups by a lookupType string.
         * @summary Get general Lookup
         * @param {string} type The type of general lookup to filter on.
         * @param {string} [subtype] The subtype to filter by. Generally its a Policy Type (Product Type) but could be something else.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralLookups(type: string, subtype?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupGeneralsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralLookups(type, subtype, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get loss of use cover for selection.
         * @summary Get trade lookups
         * @param {string} [column] The type of general lookup to filter on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLossOfUsePremiumLookup(column?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupLossOfUsePremiumsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLossOfUsePremiumLookup(column, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get regions for selection.
         * @summary Get regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionLookups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupRegionsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionLookups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get regions for selection.
         * @summary Get regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesLookups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupCitiesInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesLookups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get sectors for selection.
         * @summary Get sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectorLookups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupSectorsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectorLookups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get trades for selection.
         * @summary Get trade lookups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeLookups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupTradesInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeLookups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Interested Party lookups by a lookupType string.
         * @summary Get Interested Party Lookup
         * @param {string} type The type of policy lookup to filter on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestedPartyLookups(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupInterestedPartyInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterestedPartyLookups(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LookupApi - factory interface
 * @export
 */
export const LookupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LookupApiFp(configuration)
    return {
        /**
         * Get General lookups by a lookupType string.
         * @summary Get general Lookup
         * @param {string} type The type of general lookup to filter on.
         * @param {string} [subtype] The subtype to filter by. Generally its a Policy Type (Product Type) but could be something else.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralLookups(type: string, subtype?: string, options?: any): AxiosPromise<LookupGeneralsInData> {
            return localVarFp.getGeneralLookups(type, subtype, options).then((request) => request(axios, basePath));
        },
        /**
         * Get loss of use cover for selection.
         * @summary Get trade lookups
         * @param {string} [column] The type of general lookup to filter on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLossOfUsePremiumLookup(column?: string, options?: any): AxiosPromise<LookupLossOfUsePremiumsInData> {
            return localVarFp.getLossOfUsePremiumLookup(column, options).then((request) => request(axios, basePath));
        },
        /**
         * Get regions for selection.
         * @summary Get regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionLookups(options?: any): AxiosPromise<LookupRegionsInData> {
            return localVarFp.getRegionLookups(options).then((request) => request(axios, basePath));
        },
        /**
         * Get sectors for selection.
         * @summary Get sectors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorLookups(options?: any): AxiosPromise<LookupSectorsInData> {
            return localVarFp.getSectorLookups(options).then((request) => request(axios, basePath));
        },
        /**
         * Get trades for selection.
         * @summary Get trade lookups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeLookups(options?: any): AxiosPromise<LookupTradesInData> {
            return localVarFp.getTradeLookups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LookupApi - object-oriented interface
 * @export
 * @class LookupApi
 * @extends {BaseAPI}
 */
export class LookupApi extends BaseAPI {
    public getInterestedPartyLookup(type: string, options?: AxiosRequestConfig) {
        return LookupApiFp(this.configuration).getInterestedPartyLookups(type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get General lookups by a lookupType string.
     * @summary Get general Lookup
     * @param {string} type The type of general lookup to filter on.
     * @param {string} [subtype] The subtype to filter by. Generally its a Policy Type (Product Type) but could be something else.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupApi
     */
    public getGeneralLookups(type: string, subtype?: string, options?: AxiosRequestConfig) {
        return LookupApiFp(this.configuration).getGeneralLookups(type, subtype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get loss of use cover for selection.
     * @summary Get trade lookups
     * @param {string} [column] The type of general lookup to filter on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupApi
     */
    public getLossOfUsePremiumLookup(column?: string, options?: AxiosRequestConfig) {
        return LookupApiFp(this.configuration).getLossOfUsePremiumLookup(column, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get regions for selection.
     * @summary Get regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupApi
     */
    public getRegionLookups(options?: AxiosRequestConfig) {
        return LookupApiFp(this.configuration).getRegionLookups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get cities for selection.
     * @summary Get cities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupApi
     */
    public getCitiesLookups(options?: AxiosRequestConfig) {
        return LookupApiFp(this.configuration).getCitiesLookups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sectors for selection.
     * @summary Get sectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupApi
     */
    public getSectorLookups(options?: AxiosRequestConfig) {
        return LookupApiFp(this.configuration).getSectorLookups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get trades for selection.
     * @summary Get trade lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupApi
     */
    public getTradeLookups(options?: AxiosRequestConfig) {
        return LookupApiFp(this.configuration).getTradeLookups(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackageApi - axios parameter creator
 * @export
 */
export const PackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a Promo Code from a Package.
         * @summary Remove Promo Code from a Package.
         * @param {string} packageHash 
         * @param {string} promoCode The promo code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePromoCode: async (packageHash: string, promoCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('deletePackagePromoCode', 'packageHash', packageHash)
            // verify required parameter 'promoCode' is not null or undefined
            assertParamExists('deletePackagePromoCode', 'promoCode', promoCode)
            const localVarPath = `/packages/{packageHash}/promoCode`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (promoCode !== undefined) {
                localVarQueryParameter['promoCode'] = promoCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a package record by Package Hash.
         * @summary Get information regarding a package
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackage: async (packageHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackage', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Package Info.
         * @summary Get package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageInfo: async (packageHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackageInfo', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/info`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a package status.
         * @summary Gets a package status.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageStatus: async (packageHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackageStatus', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/status`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get packages available for the currently authenticated user.  Note: No Pagination or Filtering yet this will need to be revisited.
         * @summary Get a list of packages available to the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Package Info.
         * @summary Modify package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary'} [action] An action to perform based on the package info update.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackageInfo: async (packageHash: string, action?: 'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary', packageInfoInData?: PackageInfoInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackageInfo', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/info`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageInfoInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new package.
         * @summary Create a new package.
         * @param {PackageInData} [packageInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackage: async (packageInData?: PackageInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests a calculation on a package all policies.
         * @summary Request a premium calculation on the package.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageCalculate: async (packageHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackageCalculate', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/calculate`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests BuiltIn to Contact the user.
         * @summary Request BuiltIn to Contact.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageContact: async (packageHash: string, packageInfoInData?: PackageInfoInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackageContact', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/contact`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageInfoInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply a Promo Code to a package. Return indicates success or fail. A reload of the package for dashboard would be required to get any changes.
         * @summary Apply Promo Code to a Package.
         * @param {string} packageHash 
         * @param {string} promoCode The promo code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePromoCode: async (packageHash: string, promoCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePromoCode', 'packageHash', packageHash)
            // verify required parameter 'promoCode' is not null or undefined
            assertParamExists('postPackagePromoCode', 'promoCode', promoCode)
            const localVarPath = `/packages/{packageHash}/promoCode`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (promoCode !== undefined) {
                localVarQueryParameter['promoCode'] = promoCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save a Package as a Quote and Email it.
         * @summary Save a Package as a Quote and Email it.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {SaveAndEmailData} [saveAndEmailData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageSaveAndEmail: async (packageHash: string, saveAndEmailData?: SaveAndEmailData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackageSaveAndEmail', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/saveAndEmail`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveAndEmailData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change Package Status, e.g. Edit and Requote.
         * @summary Update package state.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {'requote' | 'apply' | 'quote' | 'proceedtopayment'} [action] The new state of the package.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageState: async (packageHash: string, action?: 'requote' | 'apply' | 'quote' | 'proceedtopayment', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackageState', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/state`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace Package Info.
         * @summary Update package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackageInfo: async (packageHash: string, packageInfoInData?: PackageInfoInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('putPackageInfo', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/info`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageInfoInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackageApi - functional programming interface
 * @export
 */
export const PackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackageApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a Promo Code from a Package.
         * @summary Remove Promo Code from a Package.
         * @param {string} packageHash 
         * @param {string} promoCode The promo code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackagePromoCode(packageHash: string, promoCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackagePromoCode(packageHash, promoCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a package record by Package Hash.
         * @summary Get information regarding a package
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackage(packageHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackage(packageHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Package Info.
         * @summary Get package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackageInfo(packageHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageInfoInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackageInfo(packageHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a package status.
         * @summary Gets a package status.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackageStatus(packageHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageStatusInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackageStatus(packageHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get packages available for the currently authenticated user.  Note: No Pagination or Filtering yet this will need to be revisited.
         * @summary Get a list of packages available to the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackagesInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Package Info.
         * @summary Modify package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary'} [action] An action to perform based on the package info update.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackageInfo(packageHash: string, action?: 'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary', packageInfoInData?: PackageInfoInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackageInfo(packageHash, action, packageInfoInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new package.
         * @summary Create a new package.
         * @param {PackageInData} [packageInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackage(packageInData?: PackageInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackage(packageInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests a calculation on a package all policies.
         * @summary Request a premium calculation on the package.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackageCalculate(packageHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageForDashboardInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackageCalculate(packageHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests BuiltIn to Contact the user.
         * @summary Request BuiltIn to Contact.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackageContact(packageHash: string, packageInfoInData?: PackageInfoInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackageContact(packageHash, packageInfoInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply a Promo Code to a package. Return indicates success or fail. A reload of the package for dashboard would be required to get any changes.
         * @summary Apply Promo Code to a Package.
         * @param {string} packageHash 
         * @param {string} promoCode The promo code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePromoCode(packageHash: string, promoCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePromoCode(packageHash, promoCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save a Package as a Quote and Email it.
         * @summary Save a Package as a Quote and Email it.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {SaveAndEmailData} [saveAndEmailData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackageSaveAndEmail(packageHash: string, saveAndEmailData?: SaveAndEmailData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackageSaveAndEmail(packageHash, saveAndEmailData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change Package Status, e.g. Edit and Requote.
         * @summary Update package state.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {'requote' | 'apply' | 'quote' | 'proceedtopayment'} [action] The new state of the package.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackageState(packageHash: string, action?: 'requote' | 'apply' | 'quote' | 'proceedtopayment', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackageState(packageHash, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replace Package Info.
         * @summary Update package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPackageInfo(packageHash: string, packageInfoInData?: PackageInfoInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPackageInfo(packageHash, packageInfoInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackageApi - factory interface
 * @export
 */
export const PackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackageApiFp(configuration)
    return {
        /**
         * Delete a Promo Code from a Package.
         * @summary Remove Promo Code from a Package.
         * @param {string} packageHash 
         * @param {string} promoCode The promo code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePromoCode(packageHash: string, promoCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePackagePromoCode(packageHash, promoCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a package record by Package Hash.
         * @summary Get information regarding a package
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackage(packageHash: string, options?: any): AxiosPromise<PackageInData> {
            return localVarFp.getPackage(packageHash, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Package Info.
         * @summary Get package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageInfo(packageHash: string, options?: any): AxiosPromise<PackageInfoInData> {
            return localVarFp.getPackageInfo(packageHash, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a package status.
         * @summary Gets a package status.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageStatus(packageHash: string, options?: any): AxiosPromise<PackageStatusInData> {
            return localVarFp.getPackageStatus(packageHash, options).then((request) => request(axios, basePath));
        },
        /**
         * Get packages available for the currently authenticated user.  Note: No Pagination or Filtering yet this will need to be revisited.
         * @summary Get a list of packages available to the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackages(options?: any): AxiosPromise<PackagesInData> {
            return localVarFp.getPackages(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Package Info.
         * @summary Modify package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary'} [action] An action to perform based on the package info update.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackageInfo(packageHash: string, action?: 'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary', packageInfoInData?: PackageInfoInData, options?: any): AxiosPromise<void> {
            return localVarFp.patchPackageInfo(packageHash, action, packageInfoInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new package.
         * @summary Create a new package.
         * @param {PackageInData} [packageInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackage(packageInData?: PackageInData, options?: any): AxiosPromise<PackageInData> {
            return localVarFp.postPackage(packageInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests a calculation on a package all policies.
         * @summary Request a premium calculation on the package.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageCalculate(packageHash: string, options?: any): AxiosPromise<PackageForDashboardInData> {
            return localVarFp.postPackageCalculate(packageHash, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests BuiltIn to Contact the user.
         * @summary Request BuiltIn to Contact.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageContact(packageHash: string, packageInfoInData?: PackageInfoInData, options?: any): AxiosPromise<void> {
            return localVarFp.postPackageContact(packageHash, packageInfoInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply a Promo Code to a package. Return indicates success or fail. A reload of the package for dashboard would be required to get any changes.
         * @summary Apply Promo Code to a Package.
         * @param {string} packageHash 
         * @param {string} promoCode The promo code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePromoCode(packageHash: string, promoCode: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.postPackagePromoCode(packageHash, promoCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Save a Package as a Quote and Email it.
         * @summary Save a Package as a Quote and Email it.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {SaveAndEmailData} [saveAndEmailData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageSaveAndEmail(packageHash: string, saveAndEmailData?: SaveAndEmailData, options?: any): AxiosPromise<void> {
            return localVarFp.postPackageSaveAndEmail(packageHash, saveAndEmailData, options).then((request) => request(axios, basePath));
        },
        /**
         * Change Package Status, e.g. Edit and Requote.
         * @summary Update package state.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {'requote' | 'apply' | 'quote' | 'proceedtopayment'} [action] The new state of the package.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackageState(packageHash: string, action?: 'requote' | 'apply' | 'quote' | 'proceedtopayment', options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.postPackageState(packageHash, action, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace Package Info.
         * @summary Update package info.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PackageInfoInData} [packageInfoInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackageInfo(packageHash: string, packageInfoInData?: PackageInfoInData, options?: any): AxiosPromise<void> {
            return localVarFp.putPackageInfo(packageHash, packageInfoInData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackageApi - object-oriented interface
 * @export
 * @class PackageApi
 * @extends {BaseAPI}
 */
export class PackageApi extends BaseAPI {
    /**
     * Delete a Promo Code from a Package.
     * @summary Remove Promo Code from a Package.
     * @param {string} packageHash 
     * @param {string} promoCode The promo code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public deletePackagePromoCode(packageHash: string, promoCode: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).deletePackagePromoCode(packageHash, promoCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a package record by Package Hash.
     * @summary Get information regarding a package
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public getPackage(packageHash: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).getPackage(packageHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Package Info.
     * @summary Get package info.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public getPackageInfo(packageHash: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).getPackageInfo(packageHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a package status.
     * @summary Gets a package status.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public getPackageStatus(packageHash: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).getPackageStatus(packageHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get packages available for the currently authenticated user.  Note: No Pagination or Filtering yet this will need to be revisited.
     * @summary Get a list of packages available to the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public getPackages(options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).getPackages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Package Info.
     * @summary Modify package info.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary'} [action] An action to perform based on the package info update.
     * @param {PackageInfoInData} [packageInfoInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public patchPackageInfo(packageHash: string, action?: 'update' | 'savequote' | 'completequote' | 'apply' | 'savequotesummary', packageInfoInData?: PackageInfoInData, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).patchPackageInfo(packageHash, action, packageInfoInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new package.
     * @summary Create a new package.
     * @param {PackageInData} [packageInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public postPackage(packageInData?: PackageInData, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).postPackage(packageInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests a calculation on a package all policies.
     * @summary Request a premium calculation on the package.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public postPackageCalculate(packageHash: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).postPackageCalculate(packageHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests BuiltIn to Contact the user.
     * @summary Request BuiltIn to Contact.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {PackageInfoInData} [packageInfoInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public postPackageContact(packageHash: string, packageInfoInData?: PackageInfoInData, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).postPackageContact(packageHash, packageInfoInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply a Promo Code to a package. Return indicates success or fail. A reload of the package for dashboard would be required to get any changes.
     * @summary Apply Promo Code to a Package.
     * @param {string} packageHash 
     * @param {string} promoCode The promo code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public postPackagePromoCode(packageHash: string, promoCode: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).postPackagePromoCode(packageHash, promoCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save a Package as a Quote and Email it.
     * @summary Save a Package as a Quote and Email it.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {SaveAndEmailData} [saveAndEmailData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public postPackageSaveAndEmail(packageHash: string, saveAndEmailData?: SaveAndEmailData, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).postPackageSaveAndEmail(packageHash, saveAndEmailData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change Package Status, e.g. Edit and Requote.
     * @summary Update package state.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {'requote' | 'apply' | 'quote' | 'proceedtopayment'} [action] The new state of the package.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public postPackageState(packageHash: string, action?: 'requote' | 'apply' | 'quote' | 'proceedtopayment', options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).postPackageState(packageHash, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replace Package Info.
     * @summary Update package info.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {PackageInfoInData} [packageInfoInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public putPackageInfo(packageHash: string, packageInfoInData?: PackageInfoInData, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).putPackageInfo(packageHash, packageInfoInData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackageAnswersApi - axios parameter creator
 * @export
 */
export const PackageAnswersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get answers for a Policy Group.
         * @summary Get answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyGroupAnswers: async (packageHash: string, policyType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackagePolicyGroupAnswers', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('getPackagePolicyGroupAnswers', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/answers/{policyType}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Answers for Policy Group.
         * @summary Set answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchPackagePolicyGroupAnswers: async (packageHash: string, policyType: string, answersInData?: AnswersInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackagePolicyGroupAnswers', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('patchPackagePolicyGroupAnswers', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/answers/{policyType}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answersInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add initial Answers for a Policy Group.
         * @summary Submit answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyGroupAnswers: async (packageHash: string, policyType: string, answersInData?: AnswersInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePolicyGroupAnswers', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('postPackagePolicyGroupAnswers', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/answers/{policyType}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answersInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Answers for Policy Group.
         * @summary Update answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyGroupAnswers: async (packageHash: string, policyType: string, answersInData?: AnswersInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('putPackagePolicyGroupAnswers', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('putPackagePolicyGroupAnswers', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/answers/{policyType}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answersInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackageAnswersApi - functional programming interface
 * @export
 */
export const PackageAnswersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackageAnswersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get answers for a Policy Group.
         * @summary Get answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagePolicyGroupAnswers(packageHash: string, policyType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswersInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagePolicyGroupAnswers(packageHash, policyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Answers for Policy Group.
         * @summary Set answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async patchPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswersInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add initial Answers for a Policy Group.
         * @summary Submit answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswersInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Answers for Policy Group.
         * @summary Update answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswersInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackageAnswersApi - factory interface
 * @export
 */
export const PackageAnswersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackageAnswersApiFp(configuration)
    return {
        /**
         * Get answers for a Policy Group.
         * @summary Get answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyGroupAnswers(packageHash: string, policyType: string, options?: any): AxiosPromise<AnswersInData> {
            return localVarFp.getPackagePolicyGroupAnswers(packageHash, policyType, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Answers for Policy Group.
         * @summary Set answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: any): AxiosPromise<AnswersInData> {
            return localVarFp.patchPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Add initial Answers for a Policy Group.
         * @summary Submit answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: any): AxiosPromise<AnswersInData> {
            return localVarFp.postPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Answers for Policy Group.
         * @summary Update answers for a policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {AnswersInData} [answersInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: any): AxiosPromise<AnswersInData> {
            return localVarFp.putPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackageAnswersApi - object-oriented interface
 * @export
 * @class PackageAnswersApi
 * @extends {BaseAPI}
 */
export class PackageAnswersApi extends BaseAPI {
    /**
     * Get answers for a Policy Group.
     * @summary Get answers for a policy type.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageAnswersApi
     */
    public getPackagePolicyGroupAnswers(packageHash: string, policyType: string, options?: AxiosRequestConfig) {
        return PackageAnswersApiFp(this.configuration).getPackagePolicyGroupAnswers(packageHash, policyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Answers for Policy Group.
     * @summary Set answers for a policy type.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {AnswersInData} [answersInData] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PackageAnswersApi
     */
    public patchPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: AxiosRequestConfig) {
        return PackageAnswersApiFp(this.configuration).patchPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add initial Answers for a Policy Group.
     * @summary Submit answers for a policy type.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {AnswersInData} [answersInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageAnswersApi
     */
    public postPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: AxiosRequestConfig) {
        return PackageAnswersApiFp(this.configuration).postPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Answers for Policy Group.
     * @summary Update answers for a policy type.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {AnswersInData} [answersInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageAnswersApi
     */
    public putPackagePolicyGroupAnswers(packageHash: string, policyType: string, answersInData?: AnswersInData, options?: AxiosRequestConfig) {
        return PackageAnswersApiFp(this.configuration).putPackagePolicyGroupAnswers(packageHash, policyType, answersInData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackageDashboardApi - axios parameter creator
 * @export
 */
export const PackageDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets package information for showing on the dashboard.
         * @summary Get package and policy information for the Dashboard.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageDashboard: async (packageHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackageDashboard', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/dashboard`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Dashboard Policy, PolicyLiability or PolicyVehicle options. PackageForDashboardInData with any recalculated fields as needed.  Which to update depends on the policyType which will choose the Type of data.  For example at the time of writing it is planned that:  if cmv then: PolicyVehicleForDashboard else if BLI then: PolicyLiabilityForDashboard else if [MDT, PI]: PolicyForDashboard otherwise: Bad Request should be returned.  Although in practice we only need to update the relevant Policy Group and Summary/Totals. May want to revise this.
         * @summary Update Package Policy Details
         * @param {string} packageHash 
         * @param {PolicyType} policyType 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackageDashboardPolicy: async (packageHash: string, policyType: PolicyType, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackageDashboardPolicy', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('patchPackageDashboardPolicy', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/dashboard/update/{policyType}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackageDashboardApi - functional programming interface
 * @export
 */
export const PackageDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackageDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets package information for showing on the dashboard.
         * @summary Get package and policy information for the Dashboard.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackageDashboard(packageHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageForDashboardInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackageDashboard(packageHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Dashboard Policy, PolicyLiability or PolicyVehicle options. PackageForDashboardInData with any recalculated fields as needed.  Which to update depends on the policyType which will choose the Type of data.  For example at the time of writing it is planned that:  if cmv then: PolicyVehicleForDashboard else if BLI then: PolicyLiabilityForDashboard else if [MDT, PI]: PolicyForDashboard otherwise: Bad Request should be returned.  Although in practice we only need to update the relevant Policy Group and Summary/Totals. May want to revise this.
         * @summary Update Package Policy Details
         * @param {string} packageHash 
         * @param {PolicyType} policyType 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackageDashboardPolicy(packageHash: string, policyType: PolicyType, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageForDashboardInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackageDashboardPolicy(packageHash, policyType, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackageDashboardApi - factory interface
 * @export
 */
export const PackageDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackageDashboardApiFp(configuration)
    return {
        /**
         * Gets package information for showing on the dashboard.
         * @summary Get package and policy information for the Dashboard.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageDashboard(packageHash: string, options?: any): AxiosPromise<PackageForDashboardInData> {
            return localVarFp.getPackageDashboard(packageHash, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Dashboard Policy, PolicyLiability or PolicyVehicle options. PackageForDashboardInData with any recalculated fields as needed.  Which to update depends on the policyType which will choose the Type of data.  For example at the time of writing it is planned that:  if cmv then: PolicyVehicleForDashboard else if BLI then: PolicyLiabilityForDashboard else if [MDT, PI]: PolicyForDashboard otherwise: Bad Request should be returned.  Although in practice we only need to update the relevant Policy Group and Summary/Totals. May want to revise this.
         * @summary Update Package Policy Details
         * @param {string} packageHash 
         * @param {PolicyType} policyType 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackageDashboardPolicy(packageHash: string, policyType: PolicyType, inlineObject?: InlineObject, options?: any): AxiosPromise<PackageForDashboardInData> {
            return localVarFp.patchPackageDashboardPolicy(packageHash, policyType, inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackageDashboardApi - object-oriented interface
 * @export
 * @class PackageDashboardApi
 * @extends {BaseAPI}
 */
export class PackageDashboardApi extends BaseAPI {
    /**
     * Gets package information for showing on the dashboard.
     * @summary Get package and policy information for the Dashboard.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageDashboardApi
     */
    public getPackageDashboard(packageHash: string, options?: AxiosRequestConfig) {
        return PackageDashboardApiFp(this.configuration).getPackageDashboard(packageHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Dashboard Policy, PolicyLiability or PolicyVehicle options. PackageForDashboardInData with any recalculated fields as needed.  Which to update depends on the policyType which will choose the Type of data.  For example at the time of writing it is planned that:  if cmv then: PolicyVehicleForDashboard else if BLI then: PolicyLiabilityForDashboard else if [MDT, PI]: PolicyForDashboard otherwise: Bad Request should be returned.  Although in practice we only need to update the relevant Policy Group and Summary/Totals. May want to revise this.
     * @summary Update Package Policy Details
     * @param {string} packageHash 
     * @param {PolicyType} policyType 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageDashboardApi
     */
    public patchPackageDashboardPolicy(packageHash: string, policyType: PolicyType, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return PackageDashboardApiFp(this.configuration).patchPackageDashboardPolicy(packageHash, policyType, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackagePaymentApi - axios parameter creator
 * @export
 */
export const PackagePaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a quote for a Finance Payment
         * @param {string} packageHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPackagePaymentFinanceQuote: async (packageHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('createPackagePaymentFinanceQuote', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/payment/finance/createQuote`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Not sure about this.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPackagePayment: async (packageHash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackagePayment', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/payment`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Not sure about this.
         * @summary Submit a payment information request.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PaymentInData} [paymentInData] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postPackagePayment: async (packageHash: string, paymentInData?: PaymentInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePayment', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/payment`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save Bank account details and finalize finance payment
         * @param {string} packageHash 
         * @param {PaymentFinanceRequest} [paymentFinanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePaymentFinanceRequest: async (packageHash: string, paymentFinanceRequest?: PaymentFinanceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePaymentFinanceRequest', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/payment/finance/request`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentFinanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests a Payment Gateway be used for Purchase.
         * @param {string} packageHash 
         * @param {PaymentRequestInData} [paymentRequestInData] The response body for a Payment Request should include a Redirect URL or error Status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePaymentRequest: async (packageHash: string, paymentRequestInData?: PaymentRequestInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePaymentRequest', 'packageHash', packageHash)
            const localVarPath = `/packages/{packageHash}/payment/request`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentRequestInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagePaymentApi - functional programming interface
 * @export
 */
export const PackagePaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackagePaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a quote for a Finance Payment
         * @param {string} packageHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPackagePaymentFinanceQuote(packageHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialSynergyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPackagePaymentFinanceQuote(packageHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Not sure about this.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPackagePayment(packageHash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagePayment(packageHash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Not sure about this.
         * @summary Submit a payment information request.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PaymentInData} [paymentInData] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postPackagePayment(packageHash: string, paymentInData?: PaymentInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePayment(packageHash, paymentInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save Bank account details and finalize finance payment
         * @param {string} packageHash 
         * @param {PaymentFinanceRequest} [paymentFinanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePaymentFinanceRequest(packageHash: string, paymentFinanceRequest?: PaymentFinanceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequestInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePaymentFinanceRequest(packageHash, paymentFinanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests a Payment Gateway be used for Purchase.
         * @param {string} packageHash 
         * @param {PaymentRequestInData} [paymentRequestInData] The response body for a Payment Request should include a Redirect URL or error Status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePaymentRequest(packageHash: string, paymentRequestInData?: PaymentRequestInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequestInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePaymentRequest(packageHash, paymentRequestInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackagePaymentApi - factory interface
 * @export
 */
export const PackagePaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackagePaymentApiFp(configuration)
    return {
        /**
         * Create a quote for a Finance Payment
         * @param {string} packageHash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPackagePaymentFinanceQuote(packageHash: string, options?: any): AxiosPromise<FinancialSynergyInfo> {
            return localVarFp.createPackagePaymentFinanceQuote(packageHash, options).then((request) => request(axios, basePath));
        },
        /**
         * Not sure about this.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPackagePayment(packageHash: string, options?: any): AxiosPromise<PaymentInData> {
            return localVarFp.getPackagePayment(packageHash, options).then((request) => request(axios, basePath));
        },
        /**
         * Not sure about this.
         * @summary Submit a payment information request.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {PaymentInData} [paymentInData] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postPackagePayment(packageHash: string, paymentInData?: PaymentInData, options?: any): AxiosPromise<PaymentInData> {
            return localVarFp.postPackagePayment(packageHash, paymentInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Save Bank account details and finalize finance payment
         * @param {string} packageHash 
         * @param {PaymentFinanceRequest} [paymentFinanceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePaymentFinanceRequest(packageHash: string, paymentFinanceRequest?: PaymentFinanceRequest, options?: any): AxiosPromise<PaymentRequestInData> {
            return localVarFp.postPackagePaymentFinanceRequest(packageHash, paymentFinanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests a Payment Gateway be used for Purchase.
         * @param {string} packageHash 
         * @param {PaymentRequestInData} [paymentRequestInData] The response body for a Payment Request should include a Redirect URL or error Status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePaymentRequest(packageHash: string, paymentRequestInData?: PaymentRequestInData, options?: any): AxiosPromise<PaymentRequestInData> {
            return localVarFp.postPackagePaymentRequest(packageHash, paymentRequestInData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackagePaymentApi - object-oriented interface
 * @export
 * @class PackagePaymentApi
 * @extends {BaseAPI}
 */
export class PackagePaymentApi extends BaseAPI {
    /**
     * Create a quote for a Finance Payment
     * @param {string} packageHash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePaymentApi
     */
    public createPackagePaymentFinanceQuote(packageHash: string, options?: AxiosRequestConfig) {
        return PackagePaymentApiFp(this.configuration).createPackagePaymentFinanceQuote(packageHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Not sure about this.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PackagePaymentApi
     */
    public getPackagePayment(packageHash: string, options?: AxiosRequestConfig) {
        return PackagePaymentApiFp(this.configuration).getPackagePayment(packageHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Not sure about this.
     * @summary Submit a payment information request.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {PaymentInData} [paymentInData] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PackagePaymentApi
     */
    public postPackagePayment(packageHash: string, paymentInData?: PaymentInData, options?: AxiosRequestConfig) {
        return PackagePaymentApiFp(this.configuration).postPackagePayment(packageHash, paymentInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save Bank account details and finalize finance payment
     * @param {string} packageHash 
     * @param {PaymentFinanceRequest} [paymentFinanceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePaymentApi
     */
    public postPackagePaymentFinanceRequest(packageHash: string, paymentFinanceRequest?: PaymentFinanceRequest, options?: AxiosRequestConfig) {
        return PackagePaymentApiFp(this.configuration).postPackagePaymentFinanceRequest(packageHash, paymentFinanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests a Payment Gateway be used for Purchase.
     * @param {string} packageHash 
     * @param {PaymentRequestInData} [paymentRequestInData] The response body for a Payment Request should include a Redirect URL or error Status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePaymentApi
     */
    public postPackagePaymentRequest(packageHash: string, paymentRequestInData?: PaymentRequestInData, options?: AxiosRequestConfig) {
        return PackagePaymentApiFp(this.configuration).postPackagePaymentRequest(packageHash, paymentRequestInData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackagePolicyClaimApi - axios parameter creator
 * @export
 */
export const PackagePolicyClaimApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a claim.
         * @summary Delete a claim.
         * @param {string} packageHash 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyClaim: async (packageHash: string, claimId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('deletePackagePolicyClaim', 'packageHash', packageHash)
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('deletePackagePolicyClaim', 'claimId', claimId)
            const localVarPath = `/packages/{packageHash}/claims/{claimId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all claims on a Package Policy.
         * @summary Get claims for a Policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyClaims: async (packageHash: string, policyType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackagePolicyClaims', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('getPackagePolicyClaims', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/claims`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates claim data.
         * @summary Modify a claim.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {number} claimId 
         * @param {string} policyType 
         * @param {PolicyClaimInData} [policyClaimInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyClaim: async (packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackagePolicyClaim', 'packageHash', packageHash)
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('patchPackagePolicyClaim', 'claimId', claimId)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('patchPackagePolicyClaim', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/claims/{claimId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyClaimInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds new claims to a Package Policy.
         * @summary Add a new claim to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyClaimsInData} [policyClaimsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyClaims: async (packageHash: string, policyType: string, policyClaimsInData?: PolicyClaimsInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePolicyClaims', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('postPackagePolicyClaims', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/claims`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyClaimsInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces claim data.
         * @summary Update a claim.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {number} claimId 
         * @param {string} policyType 
         * @param {PolicyClaimInData} [policyClaimInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyClaim: async (packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('putPackagePolicyClaim', 'packageHash', packageHash)
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('putPackagePolicyClaim', 'claimId', claimId)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('putPackagePolicyClaim', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/claims/{claimId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyClaimInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagePolicyClaimApi - functional programming interface
 * @export
 */
export const PackagePolicyClaimApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackagePolicyClaimApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a claim.
         * @summary Delete a claim.
         * @param {string} packageHash 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackagePolicyClaim(packageHash: string, claimId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackagePolicyClaim(packageHash, claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all claims on a Package Policy.
         * @summary Get claims for a Policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagePolicyClaims(packageHash: string, policyType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyClaimsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagePolicyClaims(packageHash, policyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates claim data.
         * @summary Modify a claim.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {number} claimId 
         * @param {string} policyType 
         * @param {PolicyClaimInData} [policyClaimInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackagePolicyClaim(packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackagePolicyClaim(packageHash, claimId, policyType, policyClaimInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds new claims to a Package Policy.
         * @summary Add a new claim to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyClaimsInData} [policyClaimsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePolicyClaims(packageHash: string, policyType: string, policyClaimsInData?: PolicyClaimsInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyClaimsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePolicyClaims(packageHash, policyType, policyClaimsInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces claim data.
         * @summary Update a claim.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {number} claimId 
         * @param {string} policyType 
         * @param {PolicyClaimInData} [policyClaimInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPackagePolicyClaim(packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPackagePolicyClaim(packageHash, claimId, policyType, policyClaimInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackagePolicyClaimApi - factory interface
 * @export
 */
export const PackagePolicyClaimApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackagePolicyClaimApiFp(configuration)
    return {
        /**
         * Delete a claim.
         * @summary Delete a claim.
         * @param {string} packageHash 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyClaim(packageHash: string, claimId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePackagePolicyClaim(packageHash, claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all claims on a Package Policy.
         * @summary Get claims for a Policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyClaims(packageHash: string, policyType: string, options?: any): AxiosPromise<PolicyClaimsInData> {
            return localVarFp.getPackagePolicyClaims(packageHash, policyType, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates claim data.
         * @summary Modify a claim.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {number} claimId 
         * @param {string} policyType 
         * @param {PolicyClaimInData} [policyClaimInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyClaim(packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options?: any): AxiosPromise<void> {
            return localVarFp.patchPackagePolicyClaim(packageHash, claimId, policyType, policyClaimInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds new claims to a Package Policy.
         * @summary Add a new claim to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyClaimsInData} [policyClaimsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyClaims(packageHash: string, policyType: string, policyClaimsInData?: PolicyClaimsInData, options?: any): AxiosPromise<PolicyClaimsInData> {
            return localVarFp.postPackagePolicyClaims(packageHash, policyType, policyClaimsInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces claim data.
         * @summary Update a claim.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {number} claimId 
         * @param {string} policyType 
         * @param {PolicyClaimInData} [policyClaimInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyClaim(packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options?: any): AxiosPromise<void> {
            return localVarFp.putPackagePolicyClaim(packageHash, claimId, policyType, policyClaimInData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackagePolicyClaimApi - object-oriented interface
 * @export
 * @class PackagePolicyClaimApi
 * @extends {BaseAPI}
 */
export class PackagePolicyClaimApi extends BaseAPI {
    /**
     * Delete a claim.
     * @summary Delete a claim.
     * @param {string} packageHash 
     * @param {number} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyClaimApi
     */
    public deletePackagePolicyClaim(packageHash: string, claimId: number, options?: AxiosRequestConfig) {
        return PackagePolicyClaimApiFp(this.configuration).deletePackagePolicyClaim(packageHash, claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all claims on a Package Policy.
     * @summary Get claims for a Policy type.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyClaimApi
     */
    public getPackagePolicyClaims(packageHash: string, policyType: string, options?: AxiosRequestConfig) {
        return PackagePolicyClaimApiFp(this.configuration).getPackagePolicyClaims(packageHash, policyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates claim data.
     * @summary Modify a claim.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {number} claimId 
     * @param {string} policyType 
     * @param {PolicyClaimInData} [policyClaimInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyClaimApi
     */
    public patchPackagePolicyClaim(packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options?: AxiosRequestConfig) {
        return PackagePolicyClaimApiFp(this.configuration).patchPackagePolicyClaim(packageHash, claimId, policyType, policyClaimInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds new claims to a Package Policy.
     * @summary Add a new claim to a Policy.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {PolicyClaimsInData} [policyClaimsInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyClaimApi
     */
    public postPackagePolicyClaims(packageHash: string, policyType: string, policyClaimsInData?: PolicyClaimsInData, options?: AxiosRequestConfig) {
        return PackagePolicyClaimApiFp(this.configuration).postPackagePolicyClaims(packageHash, policyType, policyClaimsInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces claim data.
     * @summary Update a claim.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {number} claimId 
     * @param {string} policyType 
     * @param {PolicyClaimInData} [policyClaimInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyClaimApi
     */
    public putPackagePolicyClaim(packageHash: string, claimId: number, policyType: string, policyClaimInData?: PolicyClaimInData, options?: AxiosRequestConfig) {
        return PackagePolicyClaimApiFp(this.configuration).putPackagePolicyClaim(packageHash, claimId, policyType, policyClaimInData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackagePolicyDirectorApi - axios parameter creator
 * @export
 */
export const PackagePolicyDirectorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a director.
         * @summary Delete a director.
         * @param {string} packageHash 
         * @param {number} directorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyDirector: async (packageHash: string, directorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('deletePackagePolicyDirector', 'packageHash', packageHash)
            // verify required parameter 'directorId' is not null or undefined
            assertParamExists('deletePackagePolicyDirector', 'directorId', directorId)
            const localVarPath = `/packages/{packageHash}/director/{directorId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"directorId"}}`, encodeURIComponent(String(directorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all Directors on a Policy.
         * @summary Get Policy Directors.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyDirectors: async (packageHash: string, policyType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackagePolicyDirectors', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('getPackagePolicyDirectors', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/directors`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates Director Information.
         * @summary Modify a Policy Director.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} directorId 
         * @param {PolicyDirectorInData} [policyDirectorInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyDirector: async (packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackagePolicyDirector', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('patchPackagePolicyDirector', 'policyType', policyType)
            // verify required parameter 'directorId' is not null or undefined
            assertParamExists('patchPackagePolicyDirector', 'directorId', directorId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/directors/{directorId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"directorId"}}`, encodeURIComponent(String(directorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyDirectorInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add Directors to a Policy.
         * @summary Add a new Director to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyDirectorsInData} [policyDirectorsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyDirectors: async (packageHash: string, policyType: string, policyDirectorsInData?: PolicyDirectorsInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePolicyDirectors', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('postPackagePolicyDirectors', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/directors`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyDirectorsInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets Director Information.
         * @summary Update a Policy Director.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} directorId 
         * @param {PolicyDirectorInData} [policyDirectorInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyDirector: async (packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('putPackagePolicyDirector', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('putPackagePolicyDirector', 'policyType', policyType)
            // verify required parameter 'directorId' is not null or undefined
            assertParamExists('putPackagePolicyDirector', 'directorId', directorId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/directors/{directorId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"directorId"}}`, encodeURIComponent(String(directorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyDirectorInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagePolicyDirectorApi - functional programming interface
 * @export
 */
export const PackagePolicyDirectorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackagePolicyDirectorApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a director.
         * @summary Delete a director.
         * @param {string} packageHash 
         * @param {number} directorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackagePolicyDirector(packageHash: string, directorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackagePolicyDirector(packageHash, directorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all Directors on a Policy.
         * @summary Get Policy Directors.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagePolicyDirectors(packageHash: string, policyType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyDirectorsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagePolicyDirectors(packageHash, policyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates Director Information.
         * @summary Modify a Policy Director.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} directorId 
         * @param {PolicyDirectorInData} [policyDirectorInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackagePolicyDirector(packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackagePolicyDirector(packageHash, policyType, directorId, policyDirectorInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add Directors to a Policy.
         * @summary Add a new Director to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyDirectorsInData} [policyDirectorsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePolicyDirectors(packageHash: string, policyType: string, policyDirectorsInData?: PolicyDirectorsInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyDirectorsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePolicyDirectors(packageHash, policyType, policyDirectorsInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets Director Information.
         * @summary Update a Policy Director.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} directorId 
         * @param {PolicyDirectorInData} [policyDirectorInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPackagePolicyDirector(packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPackagePolicyDirector(packageHash, policyType, directorId, policyDirectorInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackagePolicyDirectorApi - factory interface
 * @export
 */
export const PackagePolicyDirectorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackagePolicyDirectorApiFp(configuration)
    return {
        /**
         * Delete a director.
         * @summary Delete a director.
         * @param {string} packageHash 
         * @param {number} directorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyDirector(packageHash: string, directorId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePackagePolicyDirector(packageHash, directorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all Directors on a Policy.
         * @summary Get Policy Directors.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyDirectors(packageHash: string, policyType: string, options?: any): AxiosPromise<PolicyDirectorsInData> {
            return localVarFp.getPackagePolicyDirectors(packageHash, policyType, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates Director Information.
         * @summary Modify a Policy Director.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} directorId 
         * @param {PolicyDirectorInData} [policyDirectorInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyDirector(packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options?: any): AxiosPromise<void> {
            return localVarFp.patchPackagePolicyDirector(packageHash, policyType, directorId, policyDirectorInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Add Directors to a Policy.
         * @summary Add a new Director to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyDirectorsInData} [policyDirectorsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyDirectors(packageHash: string, policyType: string, policyDirectorsInData?: PolicyDirectorsInData, options?: any): AxiosPromise<PolicyDirectorsInData> {
            return localVarFp.postPackagePolicyDirectors(packageHash, policyType, policyDirectorsInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets Director Information.
         * @summary Update a Policy Director.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} directorId 
         * @param {PolicyDirectorInData} [policyDirectorInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyDirector(packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options?: any): AxiosPromise<void> {
            return localVarFp.putPackagePolicyDirector(packageHash, policyType, directorId, policyDirectorInData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackagePolicyDirectorApi - object-oriented interface
 * @export
 * @class PackagePolicyDirectorApi
 * @extends {BaseAPI}
 */
export class PackagePolicyDirectorApi extends BaseAPI {
    /**
     * Delete a director.
     * @summary Delete a director.
     * @param {string} packageHash 
     * @param {number} directorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyDirectorApi
     */
    public deletePackagePolicyDirector(packageHash: string, directorId: number, options?: AxiosRequestConfig) {
        return PackagePolicyDirectorApiFp(this.configuration).deletePackagePolicyDirector(packageHash, directorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all Directors on a Policy.
     * @summary Get Policy Directors.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyDirectorApi
     */
    public getPackagePolicyDirectors(packageHash: string, policyType: string, options?: AxiosRequestConfig) {
        return PackagePolicyDirectorApiFp(this.configuration).getPackagePolicyDirectors(packageHash, policyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates Director Information.
     * @summary Modify a Policy Director.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} directorId 
     * @param {PolicyDirectorInData} [policyDirectorInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyDirectorApi
     */
    public patchPackagePolicyDirector(packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options?: AxiosRequestConfig) {
        return PackagePolicyDirectorApiFp(this.configuration).patchPackagePolicyDirector(packageHash, policyType, directorId, policyDirectorInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add Directors to a Policy.
     * @summary Add a new Director to a Policy.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {PolicyDirectorsInData} [policyDirectorsInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyDirectorApi
     */
    public postPackagePolicyDirectors(packageHash: string, policyType: string, policyDirectorsInData?: PolicyDirectorsInData, options?: AxiosRequestConfig) {
        return PackagePolicyDirectorApiFp(this.configuration).postPackagePolicyDirectors(packageHash, policyType, policyDirectorsInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets Director Information.
     * @summary Update a Policy Director.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} directorId 
     * @param {PolicyDirectorInData} [policyDirectorInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyDirectorApi
     */
    public putPackagePolicyDirector(packageHash: string, policyType: string, directorId: number, policyDirectorInData?: PolicyDirectorInData, options?: AxiosRequestConfig) {
        return PackagePolicyDirectorApiFp(this.configuration).putPackagePolicyDirector(packageHash, policyType, directorId, policyDirectorInData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackagePolicyEventApi - axios parameter creator
 * @export
 */
export const PackagePolicyEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an event.
         * @summary Delete an event.
         * @param {string} packageHash 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyEvent: async (packageHash: string, eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('deletePackagePolicyEvent', 'packageHash', packageHash)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deletePackagePolicyEvent', 'eventId', eventId)
            const localVarPath = `/packages/{packageHash}/events/{eventId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Package Policy Events.
         * @summary Get events for a given policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyEvents: async (packageHash: string, policyType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackagePolicyEvents', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('getPackagePolicyEvents', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/events`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates Policy Event data.
         * @summary Modify a Policy Event.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} eventId 
         * @param {PolicyEventInData} [policyEventInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyEvent: async (packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackagePolicyEvent', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('patchPackagePolicyEvent', 'policyType', policyType)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('patchPackagePolicyEvent', 'eventId', eventId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/events/{eventId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyEventInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add Policy Events to a Package Policy.
         * @summary Add an event to a policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyEvents: async (packageHash: string, policyType: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePolicyEvents', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('postPackagePolicyEvents', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/events`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets Policy Event data.
         * @summary Update a Policy Event.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} eventId 
         * @param {PolicyEventInData} [policyEventInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyEvent: async (packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('putPackagePolicyEvent', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('putPackagePolicyEvent', 'policyType', policyType)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('putPackagePolicyEvent', 'eventId', eventId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/events/{eventId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyEventInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagePolicyEventApi - functional programming interface
 * @export
 */
export const PackagePolicyEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackagePolicyEventApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an event.
         * @summary Delete an event.
         * @param {string} packageHash 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackagePolicyEvent(packageHash: string, eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackagePolicyEvent(packageHash, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Package Policy Events.
         * @summary Get events for a given policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagePolicyEvents(packageHash: string, policyType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyEventsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagePolicyEvents(packageHash, policyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates Policy Event data.
         * @summary Modify a Policy Event.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} eventId 
         * @param {PolicyEventInData} [policyEventInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackagePolicyEvent(packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackagePolicyEvent(packageHash, policyType, eventId, policyEventInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add Policy Events to a Package Policy.
         * @summary Add an event to a policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePolicyEvents(packageHash: string, policyType: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyEventsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePolicyEvents(packageHash, policyType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets Policy Event data.
         * @summary Update a Policy Event.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} eventId 
         * @param {PolicyEventInData} [policyEventInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPackagePolicyEvent(packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPackagePolicyEvent(packageHash, policyType, eventId, policyEventInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackagePolicyEventApi - factory interface
 * @export
 */
export const PackagePolicyEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackagePolicyEventApiFp(configuration)
    return {
        /**
         * Delete an event.
         * @summary Delete an event.
         * @param {string} packageHash 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyEvent(packageHash: string, eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePackagePolicyEvent(packageHash, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Package Policy Events.
         * @summary Get events for a given policy type.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyEvents(packageHash: string, policyType: string, options?: any): AxiosPromise<PolicyEventsInData> {
            return localVarFp.getPackagePolicyEvents(packageHash, policyType, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates Policy Event data.
         * @summary Modify a Policy Event.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} eventId 
         * @param {PolicyEventInData} [policyEventInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyEvent(packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options?: any): AxiosPromise<void> {
            return localVarFp.patchPackagePolicyEvent(packageHash, policyType, eventId, policyEventInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Add Policy Events to a Package Policy.
         * @summary Add an event to a policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyEvents(packageHash: string, policyType: string, body?: object, options?: any): AxiosPromise<PolicyEventsInData> {
            return localVarFp.postPackagePolicyEvents(packageHash, policyType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets Policy Event data.
         * @summary Update a Policy Event.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} eventId 
         * @param {PolicyEventInData} [policyEventInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyEvent(packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options?: any): AxiosPromise<void> {
            return localVarFp.putPackagePolicyEvent(packageHash, policyType, eventId, policyEventInData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackagePolicyEventApi - object-oriented interface
 * @export
 * @class PackagePolicyEventApi
 * @extends {BaseAPI}
 */
export class PackagePolicyEventApi extends BaseAPI {
    /**
     * Delete an event.
     * @summary Delete an event.
     * @param {string} packageHash 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyEventApi
     */
    public deletePackagePolicyEvent(packageHash: string, eventId: number, options?: AxiosRequestConfig) {
        return PackagePolicyEventApiFp(this.configuration).deletePackagePolicyEvent(packageHash, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Package Policy Events.
     * @summary Get events for a given policy type.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyEventApi
     */
    public getPackagePolicyEvents(packageHash: string, policyType: string, options?: AxiosRequestConfig) {
        return PackagePolicyEventApiFp(this.configuration).getPackagePolicyEvents(packageHash, policyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates Policy Event data.
     * @summary Modify a Policy Event.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} eventId 
     * @param {PolicyEventInData} [policyEventInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyEventApi
     */
    public patchPackagePolicyEvent(packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options?: AxiosRequestConfig) {
        return PackagePolicyEventApiFp(this.configuration).patchPackagePolicyEvent(packageHash, policyType, eventId, policyEventInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add Policy Events to a Package Policy.
     * @summary Add an event to a policy.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyEventApi
     */
    public postPackagePolicyEvents(packageHash: string, policyType: string, body?: object, options?: AxiosRequestConfig) {
        return PackagePolicyEventApiFp(this.configuration).postPackagePolicyEvents(packageHash, policyType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets Policy Event data.
     * @summary Update a Policy Event.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} eventId 
     * @param {PolicyEventInData} [policyEventInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyEventApi
     */
    public putPackagePolicyEvent(packageHash: string, policyType: string, eventId: number, policyEventInData?: PolicyEventInData, options?: AxiosRequestConfig) {
        return PackagePolicyEventApiFp(this.configuration).putPackagePolicyEvent(packageHash, policyType, eventId, policyEventInData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackagePolicySpecifiedItemApi - axios parameter creator
 * @export
 */
export const PackagePolicySpecifiedItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a specified item.
         * @summary Delete a specified item.
         * @param {string} packageHash 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicySpecifiedItem: async (packageHash: string, itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('deletePackagePolicySpecifiedItem', 'packageHash', packageHash)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deletePackagePolicySpecifiedItem', 'itemId', itemId)
            const localVarPath = `/packages/{packageHash}/specifiedItems/{itemId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Items on the Policy Version.
         * @summary Get Items on the Policy Version.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicySpecifiedItems: async (packageHash: string, policyType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackagePolicySpecifiedItems', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('getPackagePolicySpecifiedItems', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/specifiedItems`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify a Specified Item.
         * @summary Modify a Specified Item.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicySpecifiedItem: async (packageHash: string, policyType: string, itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackagePolicySpecifiedItem', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('patchPackagePolicySpecifiedItem', 'policyType', policyType)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('patchPackagePolicySpecifiedItem', 'itemId', itemId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/specifiedItems/{itemId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add Items to the Policy Version.
         * @summary Add Items to the Policy Version.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicySpecifiedItemsInData} [policySpecifiedItemsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicySpecifiedItems: async (packageHash: string, policyType: string, policySpecifiedItemsInData?: PolicySpecifiedItemsInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePolicySpecifiedItems', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('postPackagePolicySpecifiedItems', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/specifiedItems`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policySpecifiedItemsInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Specified Item.
         * @summary Update a Specified Item.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicySpecifiedItem: async (packageHash: string, policyType: string, itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('putPackagePolicySpecifiedItem', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('putPackagePolicySpecifiedItem', 'policyType', policyType)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('putPackagePolicySpecifiedItem', 'itemId', itemId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/specifiedItems/{itemId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagePolicySpecifiedItemApi - functional programming interface
 * @export
 */
export const PackagePolicySpecifiedItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackagePolicySpecifiedItemApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a specified item.
         * @summary Delete a specified item.
         * @param {string} packageHash 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackagePolicySpecifiedItem(packageHash: string, itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackagePolicySpecifiedItem(packageHash, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Items on the Policy Version.
         * @summary Get Items on the Policy Version.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagePolicySpecifiedItems(packageHash: string, policyType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicySpecifiedItemsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagePolicySpecifiedItems(packageHash, policyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify a Specified Item.
         * @summary Modify a Specified Item.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackagePolicySpecifiedItem(packageHash: string, policyType: string, itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackagePolicySpecifiedItem(packageHash, policyType, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add Items to the Policy Version.
         * @summary Add Items to the Policy Version.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicySpecifiedItemsInData} [policySpecifiedItemsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePolicySpecifiedItems(packageHash: string, policyType: string, policySpecifiedItemsInData?: PolicySpecifiedItemsInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicySpecifiedItemsInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePolicySpecifiedItems(packageHash, policyType, policySpecifiedItemsInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a Specified Item.
         * @summary Update a Specified Item.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPackagePolicySpecifiedItem(packageHash: string, policyType: string, itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPackagePolicySpecifiedItem(packageHash, policyType, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackagePolicySpecifiedItemApi - factory interface
 * @export
 */
export const PackagePolicySpecifiedItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackagePolicySpecifiedItemApiFp(configuration)
    return {
        /**
         * Delete a specified item.
         * @summary Delete a specified item.
         * @param {string} packageHash 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicySpecifiedItem(packageHash: string, itemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePackagePolicySpecifiedItem(packageHash, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Items on the Policy Version.
         * @summary Get Items on the Policy Version.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicySpecifiedItems(packageHash: string, policyType: string, options?: any): AxiosPromise<PolicySpecifiedItemsInData> {
            return localVarFp.getPackagePolicySpecifiedItems(packageHash, policyType, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify a Specified Item.
         * @summary Modify a Specified Item.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicySpecifiedItem(packageHash: string, policyType: string, itemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.patchPackagePolicySpecifiedItem(packageHash, policyType, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add Items to the Policy Version.
         * @summary Add Items to the Policy Version.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicySpecifiedItemsInData} [policySpecifiedItemsInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicySpecifiedItems(packageHash: string, policyType: string, policySpecifiedItemsInData?: PolicySpecifiedItemsInData, options?: any): AxiosPromise<PolicySpecifiedItemsInData> {
            return localVarFp.postPackagePolicySpecifiedItems(packageHash, policyType, policySpecifiedItemsInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Specified Item.
         * @summary Update a Specified Item.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicySpecifiedItem(packageHash: string, policyType: string, itemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.putPackagePolicySpecifiedItem(packageHash, policyType, itemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackagePolicySpecifiedItemApi - object-oriented interface
 * @export
 * @class PackagePolicySpecifiedItemApi
 * @extends {BaseAPI}
 */
export class PackagePolicySpecifiedItemApi extends BaseAPI {
    /**
     * Delete a specified item.
     * @summary Delete a specified item.
     * @param {string} packageHash 
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicySpecifiedItemApi
     */
    public deletePackagePolicySpecifiedItem(packageHash: string, itemId: number, options?: AxiosRequestConfig) {
        return PackagePolicySpecifiedItemApiFp(this.configuration).deletePackagePolicySpecifiedItem(packageHash, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Items on the Policy Version.
     * @summary Get Items on the Policy Version.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicySpecifiedItemApi
     */
    public getPackagePolicySpecifiedItems(packageHash: string, policyType: string, options?: AxiosRequestConfig) {
        return PackagePolicySpecifiedItemApiFp(this.configuration).getPackagePolicySpecifiedItems(packageHash, policyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify a Specified Item.
     * @summary Modify a Specified Item.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicySpecifiedItemApi
     */
    public patchPackagePolicySpecifiedItem(packageHash: string, policyType: string, itemId: number, options?: AxiosRequestConfig) {
        return PackagePolicySpecifiedItemApiFp(this.configuration).patchPackagePolicySpecifiedItem(packageHash, policyType, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add Items to the Policy Version.
     * @summary Add Items to the Policy Version.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {PolicySpecifiedItemsInData} [policySpecifiedItemsInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicySpecifiedItemApi
     */
    public postPackagePolicySpecifiedItems(packageHash: string, policyType: string, policySpecifiedItemsInData?: PolicySpecifiedItemsInData, options?: AxiosRequestConfig) {
        return PackagePolicySpecifiedItemApiFp(this.configuration).postPackagePolicySpecifiedItems(packageHash, policyType, policySpecifiedItemsInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Specified Item.
     * @summary Update a Specified Item.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicySpecifiedItemApi
     */
    public putPackagePolicySpecifiedItem(packageHash: string, policyType: string, itemId: number, options?: AxiosRequestConfig) {
        return PackagePolicySpecifiedItemApiFp(this.configuration).putPackagePolicySpecifiedItem(packageHash, policyType, itemId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackagePolicyVehicleApi - axios parameter creator
 * @export
 */
export const PackagePolicyVehicleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a Vehicle from a Policy.
         * @summary Delete a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyVehicle: async (packageHash: string, policyType: string, vehicleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('deletePackagePolicyVehicle', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('deletePackagePolicyVehicle', 'policyType', policyType)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('deletePackagePolicyVehicle', 'vehicleId', vehicleId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/vehicles/{vehicleId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Policy Vehicles.
         * @summary Get Vehicles pertaining to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyVehicles: async (packageHash: string, policyType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('getPackagePolicyVehicles', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('getPackagePolicyVehicles', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/vehicles`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates Package Vehicle Information.
         * @summary Modify a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {PolicyVehicleInData} [policyVehicleInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyVehicle: async (packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('patchPackagePolicyVehicle', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('patchPackagePolicyVehicle', 'policyType', policyType)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('patchPackagePolicyVehicle', 'vehicleId', vehicleId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/vehicles/{vehicleId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyVehicleInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds Vehicles to a policy.
         * @summary Add a Vehicle to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyVehiclesInData} [policyVehiclesInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyVehicles: async (packageHash: string, policyType: string, policyVehiclesInData?: PolicyVehiclesInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('postPackagePolicyVehicles', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('postPackagePolicyVehicles', 'policyType', policyType)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/vehicles`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyVehiclesInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets Package Vehicle information.
         * @summary Update a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {PolicyVehicleInData} [policyVehicleInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyVehicle: async (packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageHash' is not null or undefined
            assertParamExists('putPackagePolicyVehicle', 'packageHash', packageHash)
            // verify required parameter 'policyType' is not null or undefined
            assertParamExists('putPackagePolicyVehicle', 'policyType', policyType)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('putPackagePolicyVehicle', 'vehicleId', vehicleId)
            const localVarPath = `/packages/{packageHash}/policy/{policyType}/vehicles/{vehicleId}`
                .replace(`{${"packageHash"}}`, encodeURIComponent(String(packageHash)))
                .replace(`{${"policyType"}}`, encodeURIComponent(String(policyType)))
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyVehicleInData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagePolicyVehicleApi - functional programming interface
 * @export
 */
export const PackagePolicyVehicleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackagePolicyVehicleApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a Vehicle from a Policy.
         * @summary Delete a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackagePolicyVehicle(packageHash, policyType, vehicleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Policy Vehicles.
         * @summary Get Vehicles pertaining to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackagePolicyVehicles(packageHash: string, policyType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyVehiclesInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackagePolicyVehicles(packageHash, policyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates Package Vehicle Information.
         * @summary Modify a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {PolicyVehicleInData} [policyVehicleInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPackagePolicyVehicle(packageHash, policyType, vehicleId, policyVehicleInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds Vehicles to a policy.
         * @summary Add a Vehicle to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyVehiclesInData} [policyVehiclesInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPackagePolicyVehicles(packageHash: string, policyType: string, policyVehiclesInData?: PolicyVehiclesInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyVehiclesInData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPackagePolicyVehicles(packageHash, policyType, policyVehiclesInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets Package Vehicle information.
         * @summary Update a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {PolicyVehicleInData} [policyVehicleInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPackagePolicyVehicle(packageHash, policyType, vehicleId, policyVehicleInData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackagePolicyVehicleApi - factory interface
 * @export
 */
export const PackagePolicyVehicleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackagePolicyVehicleApiFp(configuration)
    return {
        /**
         * Deletes a Vehicle from a Policy.
         * @summary Delete a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePackagePolicyVehicle(packageHash, policyType, vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Policy Vehicles.
         * @summary Get Vehicles pertaining to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackagePolicyVehicles(packageHash: string, policyType: string, options?: any): AxiosPromise<PolicyVehiclesInData> {
            return localVarFp.getPackagePolicyVehicles(packageHash, policyType, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates Package Vehicle Information.
         * @summary Modify a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {PolicyVehicleInData} [policyVehicleInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options?: any): AxiosPromise<void> {
            return localVarFp.patchPackagePolicyVehicle(packageHash, policyType, vehicleId, policyVehicleInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds Vehicles to a policy.
         * @summary Add a Vehicle to a Policy.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {PolicyVehiclesInData} [policyVehiclesInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPackagePolicyVehicles(packageHash: string, policyType: string, policyVehiclesInData?: PolicyVehiclesInData, options?: any): AxiosPromise<PolicyVehiclesInData> {
            return localVarFp.postPackagePolicyVehicles(packageHash, policyType, policyVehiclesInData, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets Package Vehicle information.
         * @summary Update a Vehicle.
         * @param {string} packageHash A package hash is a hashid of the Package Id.
         * @param {string} policyType 
         * @param {number} vehicleId 
         * @param {PolicyVehicleInData} [policyVehicleInData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options?: any): AxiosPromise<void> {
            return localVarFp.putPackagePolicyVehicle(packageHash, policyType, vehicleId, policyVehicleInData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackagePolicyVehicleApi - object-oriented interface
 * @export
 * @class PackagePolicyVehicleApi
 * @extends {BaseAPI}
 */
export class PackagePolicyVehicleApi extends BaseAPI {
    /**
     * Deletes a Vehicle from a Policy.
     * @summary Delete a Vehicle.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyVehicleApi
     */
    public deletePackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, options?: AxiosRequestConfig) {
        return PackagePolicyVehicleApiFp(this.configuration).deletePackagePolicyVehicle(packageHash, policyType, vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Policy Vehicles.
     * @summary Get Vehicles pertaining to a Policy.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyVehicleApi
     */
    public getPackagePolicyVehicles(packageHash: string, policyType: string, options?: AxiosRequestConfig) {
        return PackagePolicyVehicleApiFp(this.configuration).getPackagePolicyVehicles(packageHash, policyType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates Package Vehicle Information.
     * @summary Modify a Vehicle.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} vehicleId 
     * @param {PolicyVehicleInData} [policyVehicleInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyVehicleApi
     */
    public patchPackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options?: AxiosRequestConfig) {
        return PackagePolicyVehicleApiFp(this.configuration).patchPackagePolicyVehicle(packageHash, policyType, vehicleId, policyVehicleInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds Vehicles to a policy.
     * @summary Add a Vehicle to a Policy.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {PolicyVehiclesInData} [policyVehiclesInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyVehicleApi
     */
    public postPackagePolicyVehicles(packageHash: string, policyType: string, policyVehiclesInData?: PolicyVehiclesInData, options?: AxiosRequestConfig) {
        return PackagePolicyVehicleApiFp(this.configuration).postPackagePolicyVehicles(packageHash, policyType, policyVehiclesInData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets Package Vehicle information.
     * @summary Update a Vehicle.
     * @param {string} packageHash A package hash is a hashid of the Package Id.
     * @param {string} policyType 
     * @param {number} vehicleId 
     * @param {PolicyVehicleInData} [policyVehicleInData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagePolicyVehicleApi
     */
    public putPackagePolicyVehicle(packageHash: string, policyType: string, vehicleId: number, policyVehicleInData?: PolicyVehicleInData, options?: AxiosRequestConfig) {
        return PackagePolicyVehicleApiFp(this.configuration).putPackagePolicyVehicle(packageHash, policyType, vehicleId, policyVehicleInData, options).then((request) => request(this.axios, this.basePath));
    }
}


