import {
  PackageForDashboard,
  PackageDashboardApi,
  PackageInfo,
  PackageApi,
  PolicyType,
  InlineObject,
  InlineResponse200,
} from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../states';

export const getSummary = async (
  { commit }: ActionContext<PackageForDashboard, State>,
  { hash }: { hash: string }
): Promise<PackageForDashboard | void> => {
  const summary = (await new PackageDashboardApi().getPackageDashboard(hash))
    .data.data;
  if (summary !== undefined) {
    commit('summary', summary);
  }
  return summary;
};

export const savePolicy = async (
  { commit }: ActionContext<PackageForDashboard, State>,
  {
    hash,
    type,
    data: { data: { id, isInsured } = {} }
  }: {
    hash: string;
    type: PolicyType;
    data: InlineObject;
  }
): Promise<void> => {
  commit('policy', { id, type, isInsured });
  await new PackageDashboardApi().patchPackageDashboardPolicy(hash, type, {
    data: { id, isInsured }
  });
};

export const saveInfo = async (
  { commit, state }: ActionContext<PackageForDashboard, State>,
  {
    hash,
    info,
    create = false,
    action = undefined
  }: {
    hash?: string;
    info: PackageInfo;
    create: boolean;
    action?:
      | 'update'
      | 'savequote'
      | 'completequote'
      | 'apply'
      | 'savequotesummary';
  }
): Promise<void> => {
  commit('info', info);
  if ((state.id === undefined || state.id === null) && create) {
    const { data } = await new PackageApi().postPackage({
      data: {
        info
      }
    });
    commit('summary', data.data);
  } else if (hash !== undefined) {
    await new PackageApi().patchPackageInfo(hash, action, {
      data: info
    });
  }
};

export const contact = async (
  { commit }: ActionContext<PackageForDashboard, State>,
  {
    hash,
    info
  }: {
    hash: string;
    info: PackageInfo;
  }
): Promise<void> => {
  return new Promise((resolve, reject) => {
    commit('info', info);
    new PackageApi()
      .postPackageContact(hash, {
        data: info
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
};

export const action = async (
  _: ActionContext<PackageForDashboard, State>,
  {
    hash,
    action
  }: {
    hash: string;
    action: Parameters<PackageApi['postPackageState']>[1];
  }
): Promise<InlineResponse200 | undefined> => {
  return new Promise((resolve, reject) => {
    new PackageApi()
      .postPackageState(hash, action)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(e => {
        console.error(e);
        reject(e);
      });
  });
};
